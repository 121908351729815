import {
  Avatar,
  Box,
  Button,
  Card,
  Heading,
  HStack,
  Input,
  Text,
} from '@chakra-ui/react'
import React from 'react'
export default function PlaceOrder() {
  return (
    <Card p="10px" m="10px">
      <HStack justify="space-between" mb="23px">
        <HStack>
          <Avatar size="md" src="https://bit.ly/code-beast" />
          <Box>
            <Text className="fs--14">Cybil</Text>
            <Text className="fs--14">170 order(s)</Text>
          </Box>
        </HStack>
        <Text className="fs--14">100% </Text>
      </HStack>
      <HStack>
        <Text flex={0.5} className="fs--14">
          Price
        </Text>
        <Text flex={0.5} className="fs--14" color="primary.main">
          233.09
        </Text>
      </HStack>
      <HStack>
        <Text flex={0.5} className="fs--14">
          Available
        </Text>
        <Text flex={0.5} className="fs--14" color="primary.main">
          8954.6 USDT{' '}
        </Text>
      </HStack>
      <HStack>
        <Text flex={0.5} className="fs--14">
          Limit
        </Text>
        <Text flex={0.5} className="fs--14" color="primary.main">
          $10,000 - $100,000{' '}
        </Text>
      </HStack>
      <HStack>
        <Text flex={0.5} className="fs--14">
          Payment Method
        </Text>
        <Text flex={0.5} className="fs--14" color="primary.main">
          Bank{' '}
        </Text>
      </HStack>
      <Box mt="1rem">
        <Text className="fs--16 fw--600" mb="20px">
          How many USDT you want to buy?
        </Text>
        <Input mb="15px" placeholder="361442" />
        <Input mb="10px" placeholder="2654" />
        <Button w="100%" className="fs--14 fw--400">
          Place Order
        </Button>
      </Box>
      <Heading className="fs--16 fw--600" mb="10px" mt="1rem">
        Remarks
      </Heading>
      <Text width="70%" className="fs--14">
        Never ever mention any thing related to crypto/kucoin/usdt on wallets
        our wallets will be baned you can only mention advertising services or
        leave it empty *no third parties{' '}
      </Text>
    </Card>
  )
}
