/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, chakra } from '@chakra-ui/react'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'

export type DataTableProps<Data extends object> = {
  data: Data[]
  columns: ColumnDef<Data, any>[]
  emptyData?: JSX.Element
  height?: string | number
}

export default function DataTable<Data extends object>({
  data,
  columns,
  emptyData,
  height,
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  })

  const tableContainerRef = React.useRef<HTMLTableElement>(null)

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 5,
  })

  return (
    <div style={{ overflow: 'auto', height }}>
      <Table
        position="relative"
        ref={tableContainerRef}
        height="100%"
        bg="transparent"
      >
        <Thead position="sticky" top="0">
          {table
            .getHeaderGroups()
            .map(
              (headerGroup: {
                id: React.Key | null | undefined
                headers: any[]
              }) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    const meta: any = header.column.columnDef.meta
                    return (
                      <Th
                        fontSize="14px"
                        fontWeight="400"
                        color="text"
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        isNumeric={meta?.isNumeric}
                        textTransform="capitalize"
                        filter="auto"
                        borderColor="primary.100"
                        p="0 0 25px"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}

                        <chakra.span pl="4">
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    )
                  })}
                </Tr>
              ),
            )}
        </Thead>
        <Tbody>
          {rowVirtualizer.getVirtualItems()?.length ? (
            rowVirtualizer.getVirtualItems()?.map(virtualRow => {
              const row = rows[virtualRow?.index]
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <Td key={cell.id} px="0">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })
          ) : (
            <Tr
              css={`
                :last-child td {
                  border: none;
                }
              `}
            >
              <Td colSpan={table.getHeaderGroups()?.[0]?.headers?.length}>
                {emptyData}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  )
}
