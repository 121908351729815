import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Checkbox,
  Flex,
  Text,
  Button,
} from '@chakra-ui/react'
import UserAuth from 'components/userAuth'
import React, { useEffect, useState } from 'react'
import { useLogin } from 'utils/apis/user.api'
import { useForm, SubmitHandler } from 'react-hook-form'
import { TfiEmail } from 'react-icons/tfi'
import { FiLock } from 'react-icons/fi'
import { BiHide, BiShow } from 'react-icons/bi'
import { PhoneNumberInput } from 'components/numberInput'
import { LoginForm, LoginFormType } from 'utils/types/user.type'
import { Link, useNavigate } from 'react-router-dom'
import { FormInput } from 'components/formInput'
import Header from 'components/header'

export default function Login() {
  return (
    <>
      <Header />
      <UserAuth title="Login">
        <Tabs isLazy>
          <TabList border="none">
            <Tab>Email</Tab>
            <Tab>Number</Tab>
          </TabList>
          <TabPanels mt="35px">
            <TabPanel p="0">
              <LoginHookForm />
            </TabPanel>
            <TabPanel p="0">
              <LoginHookForm isPhone />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </UserAuth>
    </>
  )
}

function LoginHookForm({ isPhone }: LoginFormType) {
  const navigate = useNavigate()
  const { mutate: userLogin, data: response, isLoading } = useLogin()

  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm<LoginForm>()

  const onSubmit: SubmitHandler<LoginForm> = values => userLogin(values)

  useEffect(() => {
    if (response?.token) {
      localStorage.setItem('token', response?.token?.split(' ')[1])
      navigate('/profile')
    }
  }, [response])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isPhone ? (
        <PhoneNumberInput<LoginForm>
          setValue={setValue}
          name="phoneNo"
          control={control}
          isError={Boolean(errors.phoneNo)}
          errorsMessage={errors.phoneNo && errors.phoneNo?.message}
        />
      ) : (
        <FormInput
          isError={Boolean(errors.email)}
          placeholder="john@mail.com"
          label="Email"
          name="email"
          leftIcon={TfiEmail}
          register={register}
          errorMessage={errors.email && errors.email.message}
        />
      )}

      <FormInput
        isError={Boolean(errors.password)}
        label="Password"
        name="password"
        leftIcon={FiLock}
        type={show ? 'text' : 'password'}
        placeholder="Password"
        rules={{
          required: 'This is required',
          minLength: { value: 8, message: 'Minimum length should be 8' },
        }}
        register={register}
        errorMessage={errors.password && errors.password.message}
        rightIcon={show ? BiShow : BiHide}
        rightClick={handleClick}
      />

      <Flex justifyContent="space-between">
        <Checkbox defaultChecked>
          <Text fontSize="14px" color="muted">
            Remember me
          </Text>
        </Checkbox>
        <Button to="/login" as={Link} variant="link" className="fs--14 fw--400">
          Forgot password?
        </Button>
      </Flex>

      <Button
        fontWeight="400"
        w="100%"
        my="35px"
        isLoading={isLoading}
        type="submit"
      >
        Login
      </Button>

      <Text textAlign="center" className="fs--14 fw--400">
        No Account?
        <Button
          to="/register"
          as={Link}
          variant="link"
          className="fs--14 fw--400"
        >
          Register
        </Button>
      </Text>
    </form>
  )
}
