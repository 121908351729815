import {
  Avatar,
  AvatarBadge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  Text,
  VStack,
} from '@chakra-ui/react'
import React, { ChangeEvent, useState } from 'react'
import BTC from 'assets/images/BTCICon.png'
import profileBG from 'assets/images/profileBG.png'
import { BiCamera, BiHide, BiShow } from 'react-icons/bi'
import { ChevronDownIcon, ChevronRightIcon, EditIcon } from '@chakra-ui/icons'
import { Verified } from 'assets/icons'
import { ImNotification } from 'react-icons/im'
export default function AccountAndSecurity() {
  const [showChngePass, setShowChngePass] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>('Marvin McJubbet')
  const [editUserName, setEditUserName] = useState<boolean>(false)
  return (
    <>
      {!showChngePass ? (
        <>
          <Heading className="fs--30 fw--700">Account & Security</Heading>
          <Heading className="fs--20 fw--700" mt="42px">
            Personal Information
          </Heading>
          <Box
            bgImage={profileBG}
            // bgPos="center"
            bgSize="100%"
            bgRepeat="no-repeat"
            h="236px"
          >
            <Center>
              <VStack
                w="100%"
                mt={{ base: '1rem', sm: '3rem', md: '5rem', lg: '7.7rem' }}
              >
                <Avatar
                  borderWidth="4px"
                  borderColor="#FAFAFA"
                  src="https://bit.ly/kent-c-dodds"
                  size="2xl"
                >
                  <AvatarBadge
                    size="xs"
                    bottom="8px"
                    right="13px"
                    bg="white"
                    rounded="full"
                    borderColor="papayawhip"
                    as={IconButton}
                    icon={<BiCamera color="black" />}
                  />
                </Avatar>
                <Text className="fs--14" color="#7B7382">
                  User ID: 000000
                </Text>
                <Box pos="relative">
                  {!editUserName ? (
                    <>
                      {' '}
                      <Text>{userName}</Text>
                      <IconButton
                        pos="absolute"
                        top="-10px"
                        right="-7"
                        size="xs"
                        isRound
                        aria-label="edit"
                        onClick={() => setEditUserName(true)}
                        icon={<EditIcon />}
                      />
                    </>
                  ) : (
                    <>
                      <Input
                        type="string"
                        variant="flushed"
                        placeholder={userName}
                        value={userName}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setUserName(e?.target?.value)
                        }
                      />
                      <Button
                        pos="absolute"
                        top="-10px"
                        right="-7"
                        size="xs"
                        variant="outline"
                        className="fs--12"
                        onClick={() => setEditUserName(false)}
                      >
                        save
                      </Button>
                    </>
                  )}
                </Box>

                <Divider h="2px" />
              </VStack>
            </Center>
          </Box>
          <VStack
            w="100%"
            mt={{ base: '1rem', sm: '3rem', md: '5rem', lg: '7.7rem' }}
          >
            <Box display={{ base: 'block', md: 'flex' }} w="100%" gap="8px">
              <Box
                padding="19px 16px"
                rounded="6px"
                border="1px solid #EBEEEF"
                flex={0.5}
              >
                <Text className="fs--12">My Referer</Text>
                <Text className="fs--14">0000</Text>
              </Box>
              <Box
                padding="10px 16px"
                rounded="6px"
                border="1px solid #EBEEEF"
                mt={{ base: '12px', md: '0' }}
                flex={0.5}
              >
                <Text className="fs--12">Location</Text>
                <Menu matchWidth={true}>
                  <MenuButton
                    _active={{}}
                    _hover={{}}
                    bg="transparent"
                    textAlign="start"
                    color="black"
                    as={Button}
                    w="100%"
                    px="0px"
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Flex align="center">
                      <Image src={BTC} boxSize="24px" />
                      <Text ml="5px" className="fs--14">
                        BTC
                      </Text>
                    </Flex>
                  </MenuButton>
                  <MenuList minWidth="inherit">
                    <MenuItem minH="48px">
                      <Image
                        boxSize="2rem"
                        borderRadius="full"
                        src="https://placekitten.com/100/100"
                        alt="Fluffybuns the destroyer"
                        mr="12px"
                      />
                      <Text>BTC</Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Box>
            <Divider h="2px" py="1rem" />
            <Heading className="fs--20 fw--700" w="100%" py="10px">
              Security
            </Heading>
            <Box display={{ base: 'block', md: 'flex' }} w="100%" gap="8px">
              <Box
                padding="19px 16px"
                rounded="6px"
                border="1px solid #EBEEEF"
                flex={0.5}
              >
                <Text className="fs--12">Phone Number Verification</Text>
                <HStack className="fs--14">
                  <Text>+44 00000000</Text>
                  <Text className="fs--12" color="#4ECA78">
                    Verified <Verified />
                  </Text>
                </HStack>
              </Box>
              <Box
                padding="19px 16px"
                rounded="6px"
                mt={{ base: '12px', md: '0' }}
                border="1px solid #EBEEEF"
                flex={0.5}
              >
                <Text className="fs--12">Email Verification</Text>
                <HStack className="fs--14">
                  <Text>xyz00@gmail.com</Text>
                  <Text className="fs--12" color="#4ECA78">
                    Verified <Verified />
                  </Text>
                </HStack>
              </Box>
            </Box>
          </VStack>
          <Box
            mt={{ base: '12px', md: '1rem' }}
            padding="19px 16px"
            rounded="6px"
            border="1px solid #EBEEEF"
            w={{ base: '100%', md: '50%' }}
          >
            <Text className="fs--12">Change Login Password</Text>
            <HStack className="fs--14" justify="space-between">
              <Text>*********</Text>
              <Tag size="sm" variant="solid" bg="pink.100">
                <TagLabel
                  color="black"
                  onClick={() => setShowChngePass(true)}
                  cursor="pointer"
                >
                  Change Password
                </TagLabel>
              </Tag>
            </HStack>
          </Box>
        </>
      ) : (
        <>
          <Breadcrumb
            spacing="4px"
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink
                className="fs--12"
                onClick={() => setShowChngePass(false)}
              >
                Account & Security
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink className="fs--12" color="primary.main">
                Change Password
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Heading className="fs--30 fw--700">Change Password</Heading>
          <Box
            my="30px"
            py="15px"
            pr="17px"
            borderLeft="6px solid #F6BE4F"
            rounded="8px"
            boxShadow="base"
          >
            <Flex alignItems="center" pl="15px">
              <ImNotification color="#F6BE4F" />
              <Text ml="10px" className="fs--14 fw--400">
                Withdrawals, P2P selling, and payment services will be disabled
                for 24 hours after you make this change to protect your account.
              </Text>
            </Flex>
          </Box>
          <Heading className="fs--20 fw--700">Old Password</Heading>
          <Box w={{ base: '100%', md: '50%' }}>
            <ChangePassword heading="Enter Your Old Password" />
          </Box>
          <Divider h="2px" py="1rem" />
          <Heading className="fs--20 fw--700" mt="1rem">
            New Password
          </Heading>
          <Box display={{ base: 'block', md: 'flex' }} gap="8px">
            <Box flex={0.5}>
              <ChangePassword heading="Enter Your New Password" />
            </Box>
            <Box flex={0.5}>
              <ChangePassword heading="Re-enter Your New Password" />
            </Box>
          </Box>
          <Text className="fs--14" mt="20px" mb="3rem" color="primary.main">
            Verification options not available?
          </Text>
          <Button w="220px" className="fs--14">
            Confirm
          </Button>
        </>
      )}
    </>
  )
}

function ChangePassword({ heading }: { heading: string }) {
  const [showOldPass, setShowOldPass] = React.useState(false)
  const handleClick = () => setShowOldPass(!showOldPass)
  return (
    <Box mt="1rem" padding="19px 16px" rounded="6px" border="1px solid #EBEEEF">
      <Text className="fs--12" mb="10px">
        {heading}
      </Text>
      <InputGroup bg="transparent">
        <Input
          type={showOldPass ? 'text' : 'password'}
          placeholder="********"
        />
        <InputRightElement>
          <IconButton
            aria-label="showbtn"
            variant="ghost"
            size="sm"
            onClick={handleClick}
            icon={showOldPass ? <BiHide /> : <BiShow />}
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}
