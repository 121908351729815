import React from 'react'
// import P2PLayout from 'layout/P2P'
import {
  Stack,
  Tabs,
  Button,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  ButtonGroup,
  IconButton,
} from '@chakra-ui/react'
import { BiHide, BiEdit } from 'react-icons/bi'
import { RiDeleteBin6Line } from 'react-icons/ri'
export default function SellerListing() {
  return (
    // <P2PLayout>

    <Stack>
      <Tabs w="100%">
        <TabList>
          <Tab px="2.5rem">
            <Text pl={2} color="heading">
              Current
            </Text>
          </Tab>
          <Tab px="2rem">
            <Text pl={2} color="heading">
              Pending
            </Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TableContainer>
              <Table size="sm">
                <Thead bg="tableHeaderBg">
                  <Tr className="fs--14 fw--400">
                    <Th py="11px">Order ID</Th>
                    <Th py="11px">Coin</Th>
                    <Th py="11px">Price</Th>
                    <Th py="11px">Date</Th>
                    <Th py="11px">Order/Completed</Th>
                    <Th py="11px">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {[0, 1, 2, 3, 4, 5, 6, 7].map(index => {
                    return (
                      <Tr border="0px solid red" key={index}>
                        <Td border="0px">
                          <Flex py="20px" alignItems="center">
                            <Text color={index % 2 === 0 ? 'success' : 'error'}>
                              {index % 2 === 0 ? 'Buy' : 'Sell'}
                            </Text>
                            <Text
                              ml="8px"
                              className="fs--14 fw--400"
                              color="muted"
                            >
                              652asXe0...283330nM
                            </Text>
                          </Flex>
                        </Td>
                        <Td border="0px">
                          <Text>USDT</Text>
                        </Td>
                        <Td border="0px">
                          <Text>264PKR/USDT</Text>
                        </Td>
                        <Td border="0px">
                          <Text>14:36:00 02-28-22</Text>
                        </Td>
                        <Td border="0px">
                          <Text>0/10</Text>
                        </Td>
                        <Td border="0px">
                          <ButtonGroup size="sm" spacing={3}>
                            <IconButton
                              bg="#F8F7F7"
                              aria-label="Add to friends"
                              icon={<BiHide style={{ color: '#9B9A9A' }} />}
                            />
                            <IconButton
                              bg="#F8F7F7"
                              aria-label="Add to friends"
                              icon={<BiEdit style={{ color: '#9B9A9A' }} />}
                            />
                            <IconButton
                              bg="#F8F7F7"
                              aria-label="Add to friends"
                              icon={
                                <RiDeleteBin6Line
                                  style={{ color: '#9B9A9A' }}
                                />
                              }
                            />
                          </ButtonGroup>
                        </Td>
                        <Td border="0px"></Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <TableContainer>
              <Table size="sm">
                <Thead bg="tableHeaderBg">
                  <Tr className="fs--14 fw--400">
                    <Th py="11px">Order ID</Th>
                    <Th py="11px">Coin</Th>
                    <Th py="11px">Price</Th>
                    <Th py="11px">Date</Th>
                    <Th py="11px">Order/Completed</Th>
                    <Th py="11px">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {[0, 1, 2, 3, 4, 5, 6, 7].map(index => {
                    return (
                      <Tr border="0px solid red" key={index}>
                        <Td border="0px">
                          <Flex py="20px" alignItems="center">
                            <Text color={index % 2 === 0 ? 'success' : 'error'}>
                              {index % 2 === 0 ? 'Buy' : 'Sell'}
                            </Text>
                            <Text
                              ml="8px"
                              className="fs--14 fw--400"
                              color="muted"
                            >
                              652asXe0...283330nM
                            </Text>
                          </Flex>
                        </Td>
                        <Td border="0px">
                          <Text>USDT</Text>
                        </Td>
                        <Td border="0px">
                          <Text>264PKR/USDT</Text>
                        </Td>
                        <Td border="0px">
                          <Text>14:36:00 02-28-22</Text>
                        </Td>
                        <Td border="0px">
                          <Text>0/10</Text>
                        </Td>
                        <Td border="0px">
                          <ButtonGroup size="sm" spacing={3}>
                            <IconButton
                              bg="#F8F7F7"
                              aria-label="Add to friends"
                              icon={<BiEdit style={{ color: '#9B9A9A' }} />}
                            />
                            <Button
                              bg="transparent"
                              className="fs--14 fw--400"
                              color="orderText"
                            >
                              Post
                            </Button>
                          </ButtonGroup>
                        </Td>
                        <Td border="0px"></Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>

    // </P2PLayout>
  )
}
