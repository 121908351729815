import React from 'react'
// import P2PLayout from 'layout/P2P'
import MyOrder from '../MyOrders'
import Seller from '../Seller'
import P2PSellerAccount from '../SellerAccount'
import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Select,
  Box,
  Image,
  Avatar,
  HStack,
  InputGroup,
  InputRightElement,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  useMediaQuery,
  Card,
  Container,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import DataTable from 'components/table'
import C1 from 'assets/images/Currency2.png'
import { TiTick } from 'react-icons/ti'
import P2PHeader from 'assets/images/P2Pheader.png'
import rect from 'assets/images/Rectangle.png'
import { createColumnHelper } from '@tanstack/table-core'
import { P2PBuyerData } from 'utils/types/p2p.type'
import { p2pSellData } from 'utils/data/p2p.data'
import { useNavigate } from 'react-router-dom'
import { ChevronDownIcon } from '@chakra-ui/icons'
import BTC from 'assets/images/BTCICon.png'

export default function Buyer() {
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)')
  return (
    <>
      <Box bgImg={P2PHeader} bgSize="cover" bgRepeat="no-repeat" h="100%">
        {/* <Image w="100%" src={P2PHeader} /> */}
        <Text
          className="fs--24 fw--500"
          color="white"
          py="4rem"
          textAlign="center"
        >
          Buy and Sell TetherUS (USDT) with Your Preferred Payment Methods
        </Text>
      </Box>
      <Container maxW="1280px" pos="relative">
        <Box
          pos="absolute"
          top="-5.2rem"
          display={{ base: 'none', lg: 'block' }}
        >
          <Box
            maxW="284px"
            w="100%"
            borderRadius="10px"
            boxShadow="0px 0px 10px #00000012"
            m="0.75rem"
            pos="relative"
          >
            <Image src={rect} />
            <Box w="100%" textAlign="center" pos="absolute" top="3rem">
              <Avatar
                src="https://bit.ly/code-beast"
                name="Prosper Otemuyiwa"
              />
              <Text className="fs--16">Zac newton</Text>
              <Text className="fs--14" color="#666666">
                @zacnewton
              </Text>
            </Box>
            <HStack justify="space-around" mt="3.5rem" pb="1rem">
              <Box>
                <Text className="fs--14" color="#666666">
                  Sellings
                </Text>
                <Text className="fs--14 fw--500">$20k</Text>
              </Box>
              <Box>
                <Text className="fs--14" color="#666666">
                  Buyings
                </Text>
                <Text className="fs--14 fw--500">$20k</Text>
              </Box>
            </HStack>
          </Box>
        </Box>
        {isLargerThan992 ? (
          <Tabs
            p={3}
            border="1px solid #EBEEEF"
            bg="white"
            pt="20px"
            rounded="10px"
            orientation="vertical"
          >
            <TabList
              borderRadius="10px"
              boxShadow="0px 0px 10px #00000012;"
              h="max-content"
              maxW="284px"
              w="100%"
              p="1rem"
              mt="8rem"
            >
              <Tab
                border="0"
                justifyContent="start"
                _selected={{
                  fontWeight: 600,
                }}
              >
                Buy
              </Tab>
              <Tab
                border="0"
                justifyContent="start"
                _selected={{
                  fontWeight: 600,
                }}
              >
                Sell
              </Tab>
              <Tab
                border="0"
                justifyContent="start"
                _selected={{
                  fontWeight: 600,
                }}
              >
                My Order
              </Tab>
              <Tab
                border="0"
                justifyContent="start"
                _selected={{
                  fontWeight: 600,
                }}
              >
                Seller Account
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Buy />
              </TabPanel>
              <TabPanel>
                <Seller />
              </TabPanel>
              <TabPanel>
                <MyOrder />
              </TabPanel>
              <TabPanel>
                <P2PSellerAccount />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <Tabs
            p={3}
            border="1px solid #EBEEEF"
            bg="white"
            pt="20px"
            rounded="10px"
          >
            <TabList>
              <Tab border="0" justifyContent="start">
                Buy
              </Tab>
              <Tab border="0" justifyContent="start">
                Sell
              </Tab>
              <Tab border="0" justifyContent="start">
                My Order
              </Tab>
              <Tab border="0" justifyContent="start">
                Seller Account
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p="0">
                <Buy />
              </TabPanel>
              <TabPanel>
                <Seller />
              </TabPanel>
              <TabPanel>
                <MyOrder />
              </TabPanel>
              <TabPanel>
                <P2PSellerAccount />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Container>
    </>
  )
}
function Buy() {
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)')
  const navigate = useNavigate()
  return (
    <>
      {isLargerThan992 ? (
        <Tabs>
          <TabList pb="0.8rem" justifyContent="space-between">
            <HStack gap="25px">
              <Tab px="0">USDT</Tab>
              <Tab px="0">BTC</Tab>
              <Tab px="0">ETH</Tab>
              <Tab px="0">KCS</Tab>
              <Tab px="0">BTC</Tab>
              <Tab px="0">ETH</Tab>
              <Tab px="0">KCS</Tab>
            </HStack>
            <HStack>
              <InputGroup maxW="233px">
                <Input pr="4.5rem" type="type" placeholder="Enter amount" />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" variant="ghost">
                    Search
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Flex alignItems="center">
                <Text px="10px">Fiat</Text>
                <Menu matchWidth={true}>
                  <MenuButton
                    _active={{}}
                    _hover={{}}
                    bg="white"
                    textAlign="start"
                    color="black"
                    variant="outline"
                    borderColor="#e2e8f0"
                    as={Button}
                    w="100%"
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Flex alignItems="center" className="fs--16 fw--400">
                      <Image src={BTC} />
                      <Text ml="15px">BTC</Text>
                    </Flex>
                  </MenuButton>
                  <MenuList minWidth="inherit">
                    <MenuItem>
                      <Avatar size="sm" mr="12px" src={BTC} />
                      <Text>BTC</Text>
                    </MenuItem>
                    <MenuItem>
                      <Avatar
                        size="sm"
                        mr="12px"
                        src="https://placekitten.com/100/100"
                      />
                      <Text>BTC</Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </HStack>
          </TabList>

          <TabPanels>
            {[1, 2, 3, 4, 5, 6, 7].map(item => (
              <TabPanel key={item}>
                {/* Filters */}
                <DataTable columns={column} data={p2pSellData} />
                {/* Table */}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : (
        <>
          <HStack justify="space-between">
            {' '}
            <Select placeholder="USDT" color="orderText" w="max-content">
              <option value="option1">BTC</option>
              <option value="option2">ETH</option>
              <option value="option3">USD</option>
            </Select>{' '}
            <HStack alignItems="center">
              <Text px="10px">Fiat</Text>
              <Select placeholder="USD" color="orderText" w="max-content">
                <option value="option1">BTC</option>
                <option value="option2">ETH</option>
                <option value="option3">USD</option>
              </Select>
            </HStack>
          </HStack>
          {[1, 2, 3, 4, 5, 6, 7].map(index => (
            <Card bg="#FBFBFB" p="1rem" mt="12px" key={index}>
              <HStack justify="space-between" mb="23px">
                <HStack>
                  <Avatar size="sm" src="https://bit.ly/code-beast" />
                  <Text className="fs--14">Cybil Trader</Text>
                </HStack>
                <Text className="fs--14">136 orders | 100.00% </Text>
              </HStack>
              <HStack>
                <Text flex={0.25} className="fs--14">
                  Price
                </Text>
                <Text flex={0.75} className="fs--14">
                  233.09
                </Text>
              </HStack>
              <HStack>
                <Text flex={0.25} className="fs--14">
                  Quantity
                </Text>
                <Text className="fs--14" flex={0.75}>
                  8369.36 USDT
                </Text>
              </HStack>
              <HStack>
                <Text flex={0.25} className="fs--14">
                  Limit
                </Text>
                <Text className="fs--14" flex={0.75}>
                  $200.00 to $512625.00
                </Text>
              </HStack>
              <HStack justify="space-between">
                <Text className="fs--14" flex={0.25}>
                  Chain
                </Text>
                <Text className="fs--14" flex={0.63}>
                  Polygon
                </Text>
                <Button
                  variant="outline"
                  h="31px"
                  onClick={() => navigate('/buy-mobile')}
                >
                  {' '}
                  Buy
                </Button>
              </HStack>
            </Card>
          ))}
        </>
      )}
    </>
  )
}
const columnHelper = createColumnHelper<P2PBuyerData>()
const column = [
  columnHelper.accessor('advitizer', {
    cell: info => (
      <Flex alignItems="center">
        <Image boxSize="28px" mr="10px" src={C1} />
        <Flex direction="column">
          <Text
            display="flex"
            alignItems="center"
            className="fs--14 fw--400"
            color="Black"
          >
            {info.getValue()}
            <TiTick
              size="16px"
              style={{
                marginLeft: '10px',
                padding: '2.5px',
                backgroundColor: 'green',
                borderRadius: '50px',
              }}
              color="white"
            ></TiTick>
          </Text>
          <Text>136 orders | 100.00% completion</Text>
        </Flex>
      </Flex>
    ),
    header: 'Advertisers (Completion rate)',
  }),
  columnHelper.accessor('price', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'Price',
  }),
  columnHelper.accessor('limitAvilability', {
    cell: info => (
      <>
        <Text className="fs--14 fw--400" color="Black">
          Available{info.getValue()}
        </Text>
        <Text className="fs--14 fw--400" color="Black">
          limit $200.00 - $512,625.89
        </Text>
      </>
    ),
    header: 'Limit/Avilable',
  }),
  columnHelper.accessor('chain', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'Chain',
  }),
  columnHelper.accessor('action', {
    cell: info => <BuyAndSellModl data={info} />,
    header: 'Action',
  }),
]
function BuyAndSellModl({ data }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const updateStateAndOpenModal = () => {
    onOpen()
  }
  console.log(data)
  return (
    <>
      <Button
        onClick={updateStateAndOpenModal}
        className="fs--14 fw--400"
        color="primary.main"
        bg="rgba(116, 39, 152, 0.05)"
        width="67px"
        h="31px"
        borderColor="#BF63C5"
        variant="outline"
      >
        {/* {data?.row?.original?.action} */}
        Buy
      </Button>
      <Modal
        size="100px"
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent width="70%">
          <ModalCloseButton display="none" />
          <ModalBody>
            <HStack my="44px" align="start">
              <Box flex="45%">
                <Flex alignItems="center">
                  <Image boxSize="60px" mr="10px" src={C1} />
                  <Flex direction="column">
                    <Text
                      display="flex"
                      alignItems="center"
                      className="fs--24 fw--600"
                      color="Black"
                    >
                      Cybil
                      <TiTick
                        size="16px"
                        style={{
                          marginLeft: '10px',
                          padding: '2.5px',
                          backgroundColor: 'green',
                          borderRadius: '50px',
                        }}
                        color="white"
                      ></TiTick>
                    </Text>
                    <Text className="fs--16 fw--400">170 order(s)</Text>
                  </Flex>
                </Flex>
                <Text className="fs--16 fw--400" mt="11px" mb="35px">
                  Completion rate: 100%
                </Text>
                <Text className="fs--16 fw--600" mb="10px">
                  Remarks
                </Text>
                <Text width="70%" className="fs--14">
                  Never ever mention any thing related to crypto/kucoin/usdt on
                  wallets our wallets will be baned you can only mention
                  advertising services or leave it empty *no third parties{' '}
                </Text>
              </Box>
              <Box display="flex" flexDirection="column" flex="20%">
                <Text className="fs--14 fw--500" mb="10px">
                  Price
                </Text>
                <Text color="primary.main" className="fs--14" mb="10px">
                  247.29
                </Text>
                <Text className="fs--14 fw--500" mb="10px">
                  Avilable
                </Text>
                <Text color="primary.main" className="fs--14" mb="10px">
                  8954.6 USDT
                </Text>
                <Text className="fs--14 fw--500" mb="10px">
                  Limit
                </Text>
                <Text color="primary.main" className="fs--14" mb="10px">
                  $ 10,000 - 100,000
                </Text>
                <Text className="fs--14 fw--500" mb="10px">
                  Payment Method
                </Text>
                <Text color="primary.main" className="fs--14 fw--500">
                  Back
                </Text>
              </Box>
              <Box flex="35%">
                <Text className="fs--16 fw--600" mb="20px">
                  How many USDT you want to buy?
                </Text>
                <Input mb="15px" placeholder="361442" />
                <Input mb="10px" placeholder="2654" />
                <Button w="100%" className="fs--14 fw--400">
                  Place Order
                </Button>
              </Box>
            </HStack>
          </ModalBody>
          <ModalFooter display="none">
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
