import React, { useEffect, useState } from 'react'
import { Checkbox, Text, Button } from '@chakra-ui/react'
import UserAuth from 'components/userAuth'
import { useForm, SubmitHandler } from 'react-hook-form'
import { TfiEmail, TfiUser } from 'react-icons/tfi'
import { FiLock } from 'react-icons/fi'
import { BiHide, BiShow } from 'react-icons/bi'
import { SignUpForm } from 'utils/types/user.type'
import { Link, useNavigate } from 'react-router-dom'
import { FormInput } from 'components/formInput'
import { PhoneNumberInput } from 'components/numberInput'
import { useRegister } from 'utils/apis/user.api'
import Header from 'components/header'

export default function Register() {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  const { mutate: registerForm, isLoading, isSuccess, data } = useRegister()
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm<SignUpForm>()

  const onSubmit: SubmitHandler<SignUpForm> = values => registerForm(values)

  useEffect(() => {
    if (isSuccess) {
      navigate('/mobile-number-verification', {
        state: { phoneNo: data?.phoneNo },
      })
    }
  }, [isSuccess])

  return (
    <>
      <Header />
      <UserAuth title="Register">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            isError={Boolean(errors.fullName)}
            placeholder="john"
            label="Full Name"
            name="fullName"
            leftIcon={TfiUser}
            register={register}
            errorMessage={errors.fullName && errors.fullName.message}
          />
          <FormInput
            isError={Boolean(errors.email)}
            placeholder="john@mail.com"
            label="Email"
            name="email"
            leftIcon={TfiEmail}
            register={register}
            errorMessage={errors.email && errors.email.message}
          />
          <PhoneNumberInput<SignUpForm>
            name="phoneNo"
            isError={Boolean(errors.phoneNo)}
            errorsMessage={errors.phoneNo && errors.phoneNo.message}
            control={control}
            setValue={setValue}
          />
          <FormInput
            isError={Boolean(errors.password)}
            label="Password"
            name="password"
            leftIcon={FiLock}
            type={show ? 'text' : 'password'}
            placeholder="Please enter an 8-20 digit password"
            rules={{
              required: 'This is required',
              minLength: { value: 8, message: 'Minimum length should be 8' },
            }}
            register={register}
            errorMessage={errors.password && errors.password.message}
            rightIcon={show ? BiShow : BiHide}
            rightClick={handleClick}
          />
          <FormInput
            isError={Boolean(errors.referralCode)}
            placeholder="MBSYWK"
            label="Referral Code (Optional)"
            name="referralCode"
            register={register}
            errorMessage={errors.referralCode && errors.referralCode.message}
          />
          <Checkbox defaultChecked>
            <Text fontSize="14px" color="muted">
              I have read and agree to the Customer Agreement and Privacy Policy
            </Text>
          </Checkbox>
          <Button
            fontWeight="400"
            w="100%"
            my="35px"
            isLoading={isLoading}
            type="submit"
          >
            Register
          </Button>
          <Text textAlign="center" className="fs--14 fw--400">
            Existing Account?
            <Button
              to="/login"
              as={Link}
              variant="link"
              className="fs--14 fw--400"
            >
              Login
            </Button>
          </Text>
        </form>
      </UserAuth>
    </>
  )
}
