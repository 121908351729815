import React from 'react'
// import P2PLayout from 'layout/P2P'
import {
  Flex,
  Tabs,
  Input,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Select,
  HStack,
  InputGroup,
  InputRightElement,
  Button,
  Image,
} from '@chakra-ui/react'
import DataTable from 'components/table'
import C1 from 'assets/images/Currency2.png'
import { TiTick } from 'react-icons/ti'
import { p2pSellData } from 'utils/data/p2p.data'
import { P2PBuyerData } from 'utils/types/p2p.type'
import { createColumnHelper } from '@tanstack/table-core'
export default function Seller() {
  return (
    <Tabs>
      <TabList pb="0.8rem" justifyContent="space-between">
        <HStack gap="25px">
          <Tab px="0">USDT</Tab>
          <Tab px="0">BTC</Tab>
          <Tab px="0">ETH</Tab>
          <Tab px="0">KCS</Tab>
          <Tab px="0">BTC</Tab>
          <Tab px="0">ETH</Tab>
          <Tab px="0">KCS</Tab>
        </HStack>
        <HStack>
          <InputGroup maxW="233px">
            <Input pr="4.5rem" type="type" placeholder="Enter password" />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" variant="ghost">
                Search
              </Button>
            </InputRightElement>
          </InputGroup>
          <Flex alignItems="center">
            <Text px="10px">Fiat</Text>
            <Select placeholder="USD" color="orderText">
              <option value="option1">BTC</option>
              <option value="option2">ETH</option>
              <option value="option3">USD</option>
            </Select>
          </Flex>
        </HStack>
      </TabList>

      <TabPanels>
        {[1, 2, 3, 4, 5, 6, 7].map(item => (
          <TabPanel key={item}>
            {/* Filters */}
            <DataTable columns={p2pSellColumn} data={p2pSellData} />
            {/* Table */}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}
const columnHelper = createColumnHelper<P2PBuyerData>()
const p2pSellColumn = [
  columnHelper.accessor('advitizer', {
    cell: info => (
      <Flex alignItems="center">
        <Image boxSize="28px" mr="10px" src={C1} />
        <Flex direction="column">
          <Text
            display="flex"
            alignItems="center"
            className="fs--14 fw--400"
            color="Black"
          >
            {info.getValue()}
            <TiTick
              size="16px"
              style={{
                marginLeft: '10px',
                padding: '2.5px',
                backgroundColor: 'green',
                borderRadius: '50px',
              }}
              color="white"
            ></TiTick>
          </Text>
          <Text>136 orders | 100.00% completion</Text>
        </Flex>
      </Flex>
    ),
    header: 'Advertisers (Completion rate)',
  }),
  columnHelper.accessor('price', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'Price',
  }),
  columnHelper.accessor('limitAvilability', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'Limit/Avilable',
  }),
  columnHelper.accessor('chain', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'Chain',
  }),
  columnHelper.accessor('action', {
    cell: info => (
      <Button
        className="fs--14 fw--400"
        color="primary.main"
        bg="rgba(116, 39, 152, 0.05)"
        width="67px"
        h="31px"
        borderColor="#BF63C5"
        variant="outline"
      >
        {info.getValue()}
      </Button>
    ),
    header: 'Action',
  }),
]
