import React, { useState } from 'react'
// import P2PLayout from 'layout/P2P'
import {
  // Button, ButtonGroup,
  Stack,
  Flex,
  Text,
  HStack,
  Button,
  Avatar,
  Box,
  useMediaQuery,
} from '@chakra-ui/react'
import SellerListing from './MyListing'
import MyOrderListing from './MyOrder'
import CreateOrder from './CreateOrder'
import { createColumnHelper } from '@tanstack/table-core'
import { IP2pSellerAcc } from 'utils/types/p2p.type'
import { p2pSellerAcc } from 'utils/data/p2p.data'
import DataTable from 'components/table'
import C1 from 'assets/images/Currency1.png'

export default function P2PSellerAccount() {
  const [sellerTab, setSellerTab] = useState('main')
  return (
    <Stack>
      {sellerTab === 'main' && <SellerMainTab setSellerTab={setSellerTab} />}

      {sellerTab === 'my-listing' && <SellerListing />}

      {sellerTab === 'my-order' && <MyOrderListing />}

      {sellerTab === 'create-order' && <CreateOrder />}
    </Stack>
  )
}

function SellerMainTab({
  setSellerTab,
}: {
  setSellerTab: React.Dispatch<React.SetStateAction<string>>
}) {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  return (
    <>
      <Text className="fs--24 fw--600" mb="22px" cursor="pointer">
        Seller Account
      </Text>

      <Text color="muted" className="fs--16 fw--400">
        Estimated Balance
      </Text>
      <Text as="p" className="fs--40 fw--500" color="black.200">
        5.3625621
        <Text className="fs--14 fw--400" ml="0px" as="span">
          BTC
        </Text>
      </Text>

      <HStack justify="end">
        <Button variant="outline" onClick={() => setSellerTab('my-listing')}>
          My Listing
        </Button>
        <Button variant="outline" onClick={() => setSellerTab('my-order')}>
          My Order
        </Button>
        <Button onClick={() => setSellerTab('create-order')}>
          Create Order
        </Button>
      </HStack>
      <Flex direction="column">
        <Text
          textAlign="start"
          mb="10px"
          className="fs--24 fw--600"
          color="black"
        >
          Tokens
        </Text>
        {isLargerThan768 ? (
          <DataTable columns={p2pSellColumn} data={p2pSellerAcc} />
        ) : (
          <>
            {[1, 2, 3, 4, 5].map(index => (
              <HStack key={index} mt="18px">
                <Avatar src={C1} />
                <Box>
                  <Text className="fs--14">365256.32 USDT</Text>
                  <Text className="fs--14">$365256.55</Text>
                </Box>
              </HStack>
            ))}
          </>
        )}{' '}
      </Flex>
    </>
  )
}
const columnHelper = createColumnHelper<IP2pSellerAcc>()
const p2pSellColumn = [
  columnHelper.accessor('image', {
    cell: info => (
      <HStack alignItems="center">
        <Avatar src={info.getValue()} />
        <Box>
          <Text
            display="flex"
            alignItems="center"
            className="fs--14 fw--400"
            color="Black"
          >
            {info.row.original.name}
          </Text>
          <Text className="fs--14 fw--400">Theter</Text>
        </Box>
      </HStack>
    ),
    header: 'ASSETS',
  }),
  columnHelper.accessor('amount', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'TOTAL AMOUNT',
  }),
  columnHelper.accessor('available', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'AVAILABLE',
  }),
  columnHelper.accessor('BTCvalue', {
    cell: info => (
      <>
        {' '}
        <Text className="fs--14 fw--400" color="Black">
          {info.getValue()}
        </Text>
        <Text className="fs--14 fw--400" color="Black">
          $365256
        </Text>
      </>
    ),
    header: 'BTC VALUE',
  }),
  columnHelper.accessor('amount', {
    cell: info => {
      info.getValue()
    },
    header: 'Action',
  }),
]
