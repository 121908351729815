import React, { useState } from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Flex,
  Text,
  Button,
  HStack,
  useDisclosure,
  Heading,
  InputGroup,
  InputLeftElement,
  Input,
  Checkbox,
  Divider,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  ModalFooter,
} from '@chakra-ui/react'
import { ImNotification } from 'react-icons/im'
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons'
import { createColumnHelper } from '@tanstack/table-core'
import BTC from 'assets/images/BTCICon.png'
import DataTable from 'components/table'
import { assetList } from 'utils/data/profile.data'
import { AssetsList } from 'utils/types/profile.type'
import { Transfers } from 'assets/icons'
import { RxCross1 } from 'react-icons/rx'
export default function MyFunds() {
  return (
    <>
      <Text className="fs--30 fw--700">My Funds</Text>

      <Flex
        my="30px"
        alignItems="center"
        justifyContent="space-between"
        py="15px"
        pr="17px"
        borderLeft="6px solid #F6BE4F"
        rounded="8px"
        boxShadow="base"
      >
        <Flex alignItems="center" pl="15px">
          <ImNotification color="#F6BE4F" />
          <Text ml="10px" className="fs--14 fw--400">
            Your current account security is weak. You&apos;re recommended to
            activate Google 2FA verification Link
          </Text>
        </Flex>
        <RxCross1 />
      </Flex>

      <MyAssetsSelectionBox />
      <AssestsList />
    </>
  )
}

function MyAssetsSelectionBox() {
  const [currencyValue, setcurrencyValue] = useState('USDT')
  return (
    <>
      <Box rounded="10px" bg="primary.main" p="25px" mb="30px">
        <Text className="fs--16 fw--400" color="white" mb="25px">
          Total Funds
        </Text>
        <HStack alignItems="center">
          <Text className="fs--30 fw--700" color="white">
            0.00
          </Text>
          <Menu matchWidth={true}>
            <MenuButton
              _hover={{}}
              _active={{}}
              variant="ghost"
              color="white"
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              {currencyValue}
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setcurrencyValue('BTC')
                }}
              >
                BTC
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setcurrencyValue('USDt')
                }}
              >
                USDT
              </MenuItem>
            </MenuList>
          </Menu>
          <ImNotification color="white" />
        </HStack>
        <HStack align="center" justify="space-between">
          <Text className="fs--16 fw--400" color="white" mt="25px">
            0.00 USD
          </Text>
          <HStack wrap="wrap" justify="end" gap="5px">
            <ActionButton name="Tansfer" />

            <ActionButton name="Deposit" />
            <ActionButton name="Withdraw" />
          </HStack>
        </HStack>
      </Box>
    </>
  )
}
interface IActionButton {
  name: string
  onclick?: () => void
}
function ActionButton({ name, onclick }: IActionButton) {
  return (
    <Button
      variant="outline"
      className="fs--16 fw--400"
      h="34px"
      color="white"
      _hover={{
        bg: 'primary.50',
        color: 'primary.700',
      }}
      onClick={onclick}
    >
      {name}
    </Button>
  )
}
function AssestsList() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Heading className="fs--24 fw--700">Asset List</Heading>
      <HStack my="1rem">
        {' '}
        <InputGroup w="252px">
          <InputLeftElement pointerEvents="none">
            <SearchIcon />
          </InputLeftElement>

          <Input
            type="tel"
            placeholder="Search"
            focusBorderColor="primary.500"
          />
        </InputGroup>
        <Checkbox color="muted" className="fs--16 fw--400">
          Hide 0 Balances
        </Checkbox>
        <Divider orientation="vertical" h="20px" />
        <Button variant="ghost" className="fs--16 fw--400" onClick={onOpen}>
          Convert Crypto Dust Into USDT
        </Button>
      </HStack>
      <DataTable columns={columns} data={assetList} />
      <Transfer isOpen={isOpen} onClose={onClose} />
    </>
  )
}
const columnHelper = createColumnHelper<AssetsList>()
const columns = [
  columnHelper.accessor('coinName', {
    cell: info => (
      <Flex gap="5px">
        {' '}
        <Image src={info?.row?.original?.image} boxSize="22px" />
        <Text>{info?.row?.original?.coinName}</Text>
      </Flex>
    ),
    header: 'Currency',
  }),
  columnHelper.accessor('value', {
    cell: info => info.getValue(),

    header: 'Amount | Value',
  }),
  columnHelper.accessor('availableFrozen', {
    cell: info => info.getValue(),
    header: 'Available Frozen',
  }),
  columnHelper.accessor('costPrice', {
    cell: info => info.getValue(),
    header: 'Last Price | Cost Price',
  }),
  columnHelper.accessor('pnl', {
    cell: info => info.getValue(),
    header: 'Position PnL',
  }),
  columnHelper.accessor('pnl', {
    cell: info => info.getValue(),
    header: 'Operation',
  }),
]

function Transfer({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Transfer</ModalHeader>
        <ModalBody>
          {/* Currency Select start */}
          <Flex
            padding="5px"
            direction="column"
            rounded="6px"
            border="1px solid grey"
            mt="35px"
          >
            <Text mb="10px">From</Text>
            <Menu matchWidth={true}>
              <MenuButton
                _active={{}}
                _hover={{}}
                bg="transparent"
                textAlign="start"
                color="black"
                as={Button}
                w="100%"
                px="0"
                rightIcon={<ChevronDownIcon />}
              >
                BTC
              </MenuButton>
              <MenuList minWidth="inherit">
                <MenuItem>
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src="https://placekitten.com/100/100"
                    alt="Fluffybuns the destroyer"
                    mr="12px"
                  />
                  <Text>BTC</Text>
                </MenuItem>
                <MenuItem minH="40px">
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src="https://placekitten.com/120/120"
                    alt="Simon the pensive"
                    mr="12px"
                  />
                  <Text>USDT</Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Center my="1rem">
            <Transfers />
          </Center>
          <Flex
            padding="5px"
            direction="column"
            rounded="6px"
            border="1px solid grey"
          >
            <Text mb="10px">To</Text>
            <Menu matchWidth={true}>
              <MenuButton
                _active={{}}
                _hover={{}}
                bg="transparent"
                textAlign="start"
                color="black"
                px="0"
                w="100%"
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                Select
              </MenuButton>
              <MenuList minWidth="inherit">
                <MenuItem>
                  <Text color="muted">ERC20</Text>
                </MenuItem>
                <MenuItem minH="40px">
                  <Text color="muted">TRC20</Text>
                </MenuItem>
                <MenuItem minH="40px">
                  <Text color="muted">BEP20</Text>
                </MenuItem>
                <MenuItem minH="40px">
                  <Text color="muted">SQL</Text>
                </MenuItem>
                <MenuItem minH="40px">
                  <Text color="muted">POLYGON</Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Box padding="5px" rounded="6px" border="1px solid grey" mt="35px">
            <Text ps="0.3rem">Amount</Text>
            <HStack justify="space-between" ps="0.3rem">
              <Text>00</Text>
              <Menu matchWidth={true}>
                <MenuButton
                  _active={{}}
                  _hover={{}}
                  bg="transparent"
                  textAlign="start"
                  color="black"
                  as={Button}
                  px="0"
                  w="100px"
                  rightIcon={<ChevronDownIcon />}
                >
                  <Flex alignItems="center" className="fs--16 fw--400">
                    <Image src={BTC} />
                    <Text ml="15px">BTC</Text>
                  </Flex>
                </MenuButton>
                <MenuList minWidth="100px">
                  <MenuItem>
                    <Image
                      boxSize="2rem"
                      borderRadius="full"
                      src="https://placekitten.com/100/100"
                      alt="Fluffybuns the destroyer"
                      mr="12px"
                    />
                    <Text>BTC</Text>
                  </MenuItem>
                  <MenuItem>
                    <Image
                      boxSize="2rem"
                      borderRadius="full"
                      src="https://placekitten.com/120/120"
                      alt="Simon the pensive"
                      mr="12px"
                    />
                    <Text>USDT</Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button w="100%">Transfer</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
