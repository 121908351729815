/* eslint-disable react/no-children-prop */
import React, { useState } from 'react'
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Input,
  Button,
  Image,
  Highlight,
  InputRightElement,
  InputLeftElement,
  InputGroup,
  Stat,
  StatNumber,
  StatHelpText,
  Grid,
  Center,
  Card,
} from '@chakra-ui/react'
import Banner from 'assets/images/banner-bg.png'
import { HiArrowLongLeft, HiArrowLongRight } from 'react-icons/hi2'
import BgImage from 'assets/images/bg-dots.png'
import ArrowImage from 'assets/images/arrow.png'
import RegisterImage from 'assets/images/register.png'
import InvestmentImage from 'assets/images/invest.png'
import TrackImage from 'assets/images/track-investment.png'
import { CiLocationOn } from 'react-icons/ci'
import PropertyCard from 'components/propertyCard'
import { Link, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { Quotes } from 'assets/icons'
import Footer from 'components/footer'
import { useGetAllListings } from 'utils/apis/property.api'
import TestimonialsData from 'utils/data/testimonial.json'
import Mobiles from 'assets/images/mobiles.png'
import P2pImage from 'assets/images/p2p-img.png'
import Header from 'components/header'

interface ArrowType {
  className?: string
  style?: Record<string | number | symbol, undefined>
  onClick?: () => void
}
function PrevArrow(props: ArrowType) {
  const { className, style, onClick } = props
  return (
    <HiArrowLongLeft
      className={className}
      style={{
        ...style,
        color: className?.includes('slick-disabled') ? '#A3A3A3' : '#fff',
        width: '40px',
        height: '40px',
        left: '0px',
        zIndex: 1,
        background: className?.includes('slick-disabled')
          ? 'transparent'
          : 'var(--chakra-colors-primary-500)',
        padding: '10px',
        borderRadius: '50%',
        border: className?.includes('slick-disabled')
          ? '1px solid #A3A3A3'
          : '',
      }}
      onClick={onClick}
    />
  )
}

function NextArrow(props: ArrowType) {
  const { className, style, onClick } = props
  return (
    <HiArrowLongRight
      className={className}
      style={{
        ...style,
        color: className?.includes('slick-disabled') ? '#A3A3A3' : '#fff',
        width: '40px',
        height: '40px',
        right: '0px',
        borderRadius: '50%',
        background: className?.includes('slick-disabled')
          ? 'transparent'
          : 'var(--chakra-colors-primary-500)',
        border: className?.includes('slick-disabled')
          ? '1px solid #A3A3A3'
          : '',
        zIndex: 1,
        padding: '10px',
      }}
      onClick={onClick}
    />
  )
}

export default function Landing() {
  const navigate = useNavigate()
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  const { data: listings } = useGetAllListings(3)

  const [searchValue, setSearchValue] = useState('')

  const handleSearch = () => {
    navigate(`/listings?search=${searchValue}`)
  }

  return (
    <>
      <Header />
      <Box bg={`url(${BgImage}) no-repeat top left`}>
        <Flex as="section" align="center">
          <Box flex="1" pt={{ base: '50px', lg: '0' }}>
            <Box
              mx={{ base: 'auto', lg: '0' }}
              ml={{ base: '0', lg: 'auto' }}
              maxW="728px"
              px="15px"
            >
              <Heading
                as="h1"
                fontSize={{ base: '26px', md: '40', lg: '70px' }}
                position="relative"
              >
                <Highlight
                  query="your investment"
                  styles={{ color: 'primary.500', whiteSpace: 'initial' }}
                >
                  We help you with your investment
                </Highlight>
                <Image
                  position="absolute"
                  bottom="-20px"
                  right="35px"
                  src={ArrowImage}
                  alt="arrow image"
                />
              </Heading>
              <Text fontSize="18px" my="50px">
                We are helping you to find best investment opportunities in Real
                Estate.
              </Text>
              <InputGroup>
                <InputLeftElement
                  top="12px"
                  pointerEvents="none"
                  children={<CiLocationOn fontSize="22px" />}
                />
                <Input
                  onChange={e => setSearchValue(e?.target?.value)}
                  h="65px"
                  placeholder="Please enter location"
                />
                <InputRightElement
                  top="12px"
                  right="12px"
                  w="96px"
                  h="45px"
                  children={
                    <Button fontSize="12px" onClick={handleSearch}>
                      Search Now
                    </Button>
                  }
                />
              </InputGroup>
            </Box>
          </Box>
          <Box
            display={{ base: 'none', lg: 'block' }}
            flex="1"
            textAlign="right"
          >
            <Image w="100%" objectFit="cover" src={Banner} />
          </Box>
        </Flex>
      </Box>
      <Container maxW="1280px">
        <Card
          direction="row"
          maxW="868px"
          mx="auto"
          my="60px"
          bg="gray.50"
          justifyContent={{ base: 'space-evenly', lg: 'space-between' }}
          p={{ base: '15px', lg: '20px 30px' }}
          flexWrap="wrap"
          gap={{ base: '20px', lg: '0' }}
        >
          <Stats label="Projects" value="+5M" />
          <Stats label="Years of Experience" value="+5M" />
          <Stats label="Funds Received" value="+5M" />
          <Stats label="Total Customers" value="+5M" />
        </Card>
        <Box as="section" mt="60px">
          <Flex justifyContent="space-between">
            <Heading
              as="h2"
              fontSize={{ base: '22px', md: '26px', lg: '36px' }}
            >
              Recently Listed Opportunities
            </Heading>
            <Button variant="ghost" as={Link} to="/listings" gap="10px">
              Explore More <HiArrowLongRight fontSize="32px" />
            </Button>
          </Flex>
          <Grid
            gridTemplateColumns="repeat(auto-fill, minmax(340px, 1fr))"
            gap="30px"
            mt="35px"
          >
            {listings?.pages?.[0]?.map(item => (
              <PropertyCard key={item?.id} property={item} />
            ))}
          </Grid>
          {!listings?.pages?.[0]?.[0] && (
            <Center mt="35px" textAlign="center" minH="460px">
              <Text fontWeight="900" fontSize="62px">
                Coming Soon
              </Text>
            </Center>
          )}
        </Box>
        <Box as="section" textAlign="center" my="100px" id="/#how-it-works">
          <Heading as="h2">How it works</Heading>
          <Text maxW="570px" m="25px auto 45px">
            We have simplified the process of investing to make every step from
            registration, through to investment and tracking, easy!
          </Text>
          <Flex direction={{ base: 'column', md: 'row' }} gap="28px">
            <HowItWorksCard
              count="01"
              image={RegisterImage}
              title="Get Registered"
              info="Sign up and explore multiple opportunities in projects at varying stages
        of development."
            />
            <HowItWorksCard
              count="02"
              image={InvestmentImage}
              title="Find Opportunities & Invest"
              info="Use the platform to compare different projects that align with
              your goals, giving you the ability to invest on the go."
            />
            <HowItWorksCard
              count="03"
              image={TrackImage}
              title="Track Your Progress"
              info="Your personalised dashboard allows you to track the progress
              of your portfolio and monitor your returns"
            />
          </Flex>
        </Box>
        <Flex as="section" pb="100px">
          <Box flex="1">
            <Image src={Mobiles} />
          </Box>
          <Box flex="1">
            <Heading as="h2" fontSize="40px">
              Let’s find more that brings us together.
            </Heading>
            <Text color="muted" my="23px">
              EXTSY helps you connect with friends, family and communities of
              people who share your interests. Connecting with your friends and
              family as well as discovering new ones is easy with features like
              Groups, Watch and Marketplace.
            </Text>
            <Button
              maxW="260px"
              w="100%"
              justifyContent="space-between"
              size="lg"
              variant="outline"
              rightIcon={<HiArrowLongRight fontSize="32px" />}
            >
              Socialize Now
            </Button>
          </Box>
        </Flex>
        <Flex as="section">
          <Box flex="1">
            <Heading as="h2" fontSize="40px">
              P2P
            </Heading>
            <Text color="muted" my="23px">
              Over 30 coins available. Buy and sell quickly with automatic
              calculation of average cost and PnL.
            </Text>
            <Button
              maxW="260px"
              w="100%"
              justifyContent="space-between"
              size="lg"
              variant="outline"
              rightIcon={<HiArrowLongRight fontSize="32px" />}
            >
              Trade Now
            </Button>
          </Box>
          <Box flex="1">
            <Image src={P2pImage} />
          </Box>
        </Flex>
        <Box as="section" py={{ base: '40px', md: '100px' }} textAlign="center">
          <Heading as="h2" fontSize="40px" mb="45px">
            Testimonials
          </Heading>
          <Slider {...settings}>
            {TestimonialsData?.map(Testimonial => (
              <Box key={Testimonial?.name}>
                <Box
                  p="30px 25px"
                  bg="white"
                  rounded="8px"
                  maxW="746px"
                  mx="auto"
                >
                  <Quotes />
                  <Text color="muted" my="25px" mx="auto" maxW="650px">
                    {Testimonial?.message}
                  </Text>
                  <Text as="span" display="block" className="fs--20px fw--700">
                    {Testimonial?.name}
                  </Text>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Container>
      <Footer />
    </>
  )
}

function Stats({ label, value }: { label: string; value: string }) {
  return (
    <Stat textAlign="center" maxW="max-content">
      <StatNumber mb="17px" fontSize="20px">
        {value}
      </StatNumber>
      <StatHelpText fontSize="18px" m="0">
        {label}
      </StatHelpText>
    </Stat>
  )
}

function HowItWorksCard({
  image,
  count,
  title,
  info,
}: {
  image: string
  count: string
  title: string
  info: string
}) {
  return (
    <Box
      flex="1"
      rounded="16px"
      border="3px solid"
      borderColor="primary.500"
      p="45px 30px"
    >
      <Center
        color="white"
        mx="auto"
        w="60px"
        h="60px"
        boxShadow="0 0 0px 10px #E7CEFC"
        rounded="5px"
        bg="primary.500"
      >
        {count}
      </Center>
      <Image
        my="35px"
        display={{ base: 'none', lg: 'inline' }}
        src={image}
        alt="works img"
      />
      <Heading fontSize="20px" as="h4">
        {title}
      </Heading>
      <Text m="16px 0 35px">{info}</Text>
    </Box>
  )
}
