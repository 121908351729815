import {
  AssetsFuture,
  AssetsList,
  IDeposit,
  IP2PHistory,
  IWithDraw,
} from 'utils/types/profile.type'

export const assetList: AssetsList[] = [
  {
    coinName: 'BTC',
    image:
      'https://crypto.com/price/_next/image?url=https%3A%2F%2Fstatic.crypto.com%2Ftoken%2Ficons%2Fbitcoin%2Fcolor_icon.png&w=32&q=75',
    value: 34.55,
    availableFrozen: 0.0,
    costPrice: 1234.99,
    pnl: '--',
  },
]
export const assetsFuture: AssetsFuture[] = [
  {
    coinName: 'BTC',
    image:
      'https://crypto.com/price/_next/image?url=https%3A%2F%2Fstatic.crypto.com%2Ftoken%2Ficons%2Fbitcoin%2Fcolor_icon.png&w=32&q=75',
    equity: 3232,
    marginRate: '--',
    available: 'Available',
    positionMargin: 434.034,
    unRealizedPnl: 0.0,
    trailFund: 0.0,
  },
]
export const depositData: IDeposit[] = [
  {
    time: '2023-02-14 14:00',
    type: 'Deposit',
    depositWallet: 'Spot wallet',
    asset: 'MATIC',
    amount: 33.0,
    destination: '0x0000...0000',
    txID: '0x0000...0000',
    status: 'Completed',
  },
]
export const p2pHistory: IP2PHistory[] = [
  {
    orderID: '000xx..0000000',
    status: 'Completed',
    amount: 0.33,
    price: 0.555,
    network: 'BSC',
    quantity: 0.0,
    time: '2023-02-21 15:12',
  },
  {
    orderID: '000xx..0000000',
    status: 'Not completed',
    amount: 0.76,
    price: 0.99,
    network: 'BSC',
    quantity: 0.0,
    time: '2023-04-21 5:12',
  },
]

export const withDraw: IWithDraw[] = []
