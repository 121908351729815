import React from 'react'
import { Flex, Text } from '@chakra-ui/layout'
import logo from 'assets/images/logo.svg'
import { Image } from '@chakra-ui/image'
import { Button, ButtonGroup } from '@chakra-ui/button'
import { menu } from 'utils/const'
import { Link } from 'react-router-dom'
import { useIsLogin } from 'hooks/index'

export default function Header() {
  const isLogin = useIsLogin()
  return (
    <Flex
      bg="white"
      as="header"
      p="1.3rem"
      alignItems="center"
      boxShadow=" 0px 0px 15px 0px #0000001A"
    >
      <Link to="/">
        <Image src={logo} />
      </Link>
      <Flex as="nav" flex="1" ml={'7rem'} gap="2" align="center">
        {menu.map(
          ({ label, path }) =>
            path && (
              <Text
                as={Link}
                to={path}
                key={label}
                fontSize="14px"
                fontWeight="300"
                mr="1rem"
              >
                {label}
              </Text>
            ),
        )}
      </Flex>
      {!isLogin ? (
        <ButtonGroup gap="2" pr="2rem">
          <Button
            to="/login"
            as={Link}
            fontSize="12px"
            variant="ghost"
            w="90px"
          >
            Login
          </Button>
          <Button to="/register" as={Link} fontSize="12px" w="90px">
            Register
          </Button>
        </ButtonGroup>
      ) : (
        <Button
          onClick={() => localStorage.removeItem('token')}
          to="/"
          as={Link}
          fontSize="12px"
          w="90px"
        >
          Logout
        </Button>
      )}
    </Flex>
  )
}
