import { useMutation } from '@tanstack/react-query'
import {
  LoginForm,
  LoginResPonse,
  SignUpForm,
  User,
  VerifyEmail,
  VerifyPhoneNumber,
  VerifyPhoneNumberRes,
  VerifyEmailRes,
} from 'utils/types/user.type'
import { client } from './api-client'

export const useLogin = () =>
  useMutation<LoginResPonse, Error, LoginForm>(data =>
    client('auth/loginByEmail', {
      data,
    }),
  )

export const useRegister = () =>
  useMutation<User, Error, SignUpForm>(data =>
    client('auth/register', {
      data,
    }),
  )

export const useVerifyPhoneNumber = () =>
  useMutation<VerifyPhoneNumberRes, Error, VerifyPhoneNumber>(data =>
    client('auth/verifyPhoneNumberOnSignup', {
      data,
    }),
  )

export const useVerifyEmail = () =>
  useMutation<VerifyEmailRes, Error, VerifyEmail>(data =>
    client('auth/verifyEmailOnSignup', {
      data,
    }),
  )
