import React from 'react'

import {
  HStack,
  Box,
  SimpleGrid,
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Stack,
  Textarea,
  VStack,
  Heading,
  Avatar,
  AvatarBadge,
  IconButton,
  CloseButton,
  useMediaQuery,
} from '@chakra-ui/react'
import { MdVerifiedUser } from 'react-icons/md'
import { Attachment } from 'assets/icons'
import commentImg from 'assets/images/comment.svg'
export default function MyOrder() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [showChat, setShowChat] = React.useState(false)
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2 }} gridGap="1rem">
        <Box
          display={!isLargerThan768 ? (!showChat ? 'block' : 'none') : 'block'}
        >
          <HStack
            justify="space-between"
            display={{ base: 'flex', md: 'none' }}
          >
            <Heading className="fs--16">Order Creaded</Heading>
            <Avatar
              size="sm"
              src={commentImg}
              objectFit="cover"
              onClick={() => setShowChat(true)}
            >
              <AvatarBadge
                bg="tomato"
                border="0"
                top="0"
                right="2px"
                boxSize="0.7rem"
              />
            </Avatar>
          </HStack>
          <HStack justify="space-between">
            <Text w="100%" className="fs--16 fw-500" my="1rem" bg="#F6F6F6">
              Order Details{' '}
            </Text>
            <Text className="fs--14">*pending</Text>
          </HStack>

          <SimpleGrid columns={2} spacing="17px">
            <Box>
              <Text className="fs--14 fw--400">Buy Order</Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400" color="primary.main">
                98.666 USDT
              </Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400">Buy Order</Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400" color="primary.main">
                19005.6 PKR
              </Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400">Buy Order</Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400" color="primary.main">
                247.29
              </Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400">QTY</Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400" color="primary.main">
                98 USDT
              </Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400">Provider</Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400" color="primary.main">
                Cybil
              </Text>
            </Box>
          </SimpleGrid>
          {/* Payment Method  Start*/}

          <Text className="fs--16 fw-500" my="1rem" bg="#F6F6F6">
            Payment Method
          </Text>

          <SimpleGrid columns={2} spacing="17px">
            <Box>
              <Text className="fs--14 fw--400">Your Bank Name</Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400" color="primary.main">
                Maclaurance
              </Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400">Full name</Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400" color="primary.main">
                Maclaurance Dae
              </Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400">Account Number</Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400" color="primary.main">
                026737023001
              </Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400">Swift</Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400" color="primary.main">
                HSBC Middle East
              </Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400">IBAN</Text>
            </Box>
            <Box>
              <Text className="fs--14 fw--400" color="primary.main">
                AE190200...73023001
              </Text>
            </Box>
          </SimpleGrid>
          <Box mt="3rem">
            <Flex className="fs--14 fw--400" mb="15px">
              <Text>
                Pay the seller within
                <Text
                  as="span"
                  color="orderText"
                  className="fs--14 fw--600"
                  ml="10px"
                >
                  15:00
                </Text>
              </Text>
            </Flex>
            <Flex mb="15px" alignItems="start">
              <MdVerifiedUser
                style={{
                  color: '#4ECA78',
                  width: '15px',
                  height: '20px',
                  marginTop: '2px',
                }}
              />
              <Text className="fs--14 fw--400">
                Extsy is holding the seller’s crpto in the<br></br>
                escrow account
              </Text>
            </Flex>
            <Flex mb="30px" alignItems="start">
              <MdVerifiedUser
                style={{
                  color: '#4ECA78',
                  width: '15px',
                  height: '20px',
                  marginTop: '2px',
                }}
              />
              <Text className="fs--14 fw--400">
                Extsy 24/7 customer support
              </Text>
            </Flex>
            <Flex direction="column">
              <Button
                _hover={{}}
                _focus={{}}
                _active={{}}
                bg="#7C167D"
                mb="15px"
                w="100%"
                className="fw--400"
              >
                Mark as Paid
              </Button>
              <Button
                _hover={{}}
                _focus={{}}
                _active={{}}
                color="muted"
                bg="#E7EAEE"
                w="100%"
                className="fw--400"
                onClick={onOpen}
              >
                Cancel
              </Button>
            </Flex>
          </Box>
        </Box>

        <Box
          display={!isLargerThan768 ? (showChat ? 'block' : 'none') : 'block'}
        >
          <Stack
            bg=" #F6F6F6"
            h="90vh"
            direction="column"
            justify="space-between"
          >
            <VStack gap="4px" align="flex-start" p="25px">
              <IconButton
                display={{ base: 'block', md: 'none' }}
                m="0 0 0 auto"
                isRound
                size="sm"
                w="max-content"
                onClick={() => setShowChat(false)}
                aria-label="closebtn"
                icon={<CloseButton fontSize="8px" />}
              />
              <Text
                bg="#ECECED"
                className="fs--14"
                w="max-content"
                borderRadius="5px"
                p="5px 15px"
              >
                Hi
              </Text>
              <Text
                as={'span'}
                bg="#ECECED"
                className="fs--14"
                borderRadius="5px"
                p="5px 15px"
              >
                3rd party payment not acceptable please make payment from your
                own account...
              </Text>
              <Text className="fs--11">Yesterday, 8:30pm</Text>
              <Box w="100%">
                <Text
                  ml="auto"
                  bg="primary.main"
                  className="fs--14"
                  w="max-content"
                  borderRadius="5px"
                  p="5px 15px"
                  color="white"
                >
                  Hello there!
                </Text>
              </Box>
            </VStack>
            <Box borderTop="2px" borderColor="#DFDFDF" p="25px">
              <HStack p="0.4rem" align="start">
                <Attachment />
                <Textarea
                  pt="0"
                  className="fs--16"
                  placeholder="Type here..."
                  variant="unstyled"
                />
                <Button>Send</Button>
              </HStack>
            </Box>
          </Stack>
        </Box>
      </SimpleGrid>
      <OrderModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}
function OrderModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mx="7px">
        <ModalHeader className="fs--16 fw--600" pb={0}>
          Cancel Order
        </ModalHeader>
        <ModalBody my="10px" py="0px">
          <Text className="fw--400 fs--14">
            The funds will not be refunded if you cancel the order. If the
            payment is completed, please DO NOT cancel the order.
          </Text>
        </ModalBody>

        <ModalFooter gap="7px" flexDir={{ base: 'column', md: 'row' }}>
          <Button
            _hover={{}}
            _focus={{}}
            _active={{}}
            color="muted"
            bg="#E7EAEE"
            width="100%"
            className="fs--14 fw--400"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            _hover={{}}
            _focus={{}}
            _active={{}}
            bg="#7C167D"
            width="100%"
            className="fs--14 fw--400"
            onClick={onClose}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
