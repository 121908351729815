import React from 'react'
import {
  RegisterOptions,
  UseFormRegister,
  Path,
  FieldValues,
} from 'react-hook-form'
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'

export type FormInputProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>
  rules?: RegisterOptions
  register: UseFormRegister<TFormValues>
  isError?: boolean
  placeholder: string
  label: string
  errorMessage?: string
  helperText?: string
  type?: string
  inputProps?: InputProps
  formProps?: FormControlProps
  // eslint-disable-next-line
  leftIcon?: any // @typescript-eslint/no-explicit-any
  // eslint-disable-next-line
  rightIcon?: any // @typescript-eslint/no-explicit-any
  rightClick?: () => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormInput = <TFormValues extends Record<string, any>>({
  name,
  label,
  register,
  rules,
  isError,
  placeholder,
  errorMessage,
  type,
  inputProps,
  formProps,
  helperText,
  leftIcon,
  rightIcon,
  rightClick,
}: FormInputProps<TFormValues>): JSX.Element => {
  return (
    <FormControl {...formProps} isInvalid={isError} mb="20px">
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        {leftIcon && (
          <InputLeftElement
            pointerEvents="none"
            // eslint-disable-next-line react/no-children-prop
            children={<Icon as={leftIcon} color="gray.400" />}
          />
        )}
        <Input
          bg="white"
          type={type}
          focusBorderColor="primary.500"
          {...register(name, rules)}
          placeholder={placeholder}
          {...inputProps}
        />
        {rightIcon && (
          <InputRightElement
            // eslint-disable-next-line react/no-children-prop
            children={
              <Icon as={rightIcon} color="gray.400" onClick={rightClick} />
            }
          />
        )}
      </InputGroup>
      <FormHelperText fontSize="12px" fontStyle="italic" color="orange.400">
        {helperText}
      </FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
