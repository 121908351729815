import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Input,
  Text,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import P2PCurrency from 'components/p2pCurrency'
import P2PNetwork from 'components/P2PNetwork'
import DataTable from 'components/table'
import React from 'react'
import { GrBitcoin } from 'react-icons/gr'
import { withDraw } from 'utils/data/profile.data'
import { IWithDraw } from 'utils/types/profile.type'

export default function AssetTransfer() {
  return (
    <>
      <Heading>Widthdraw</Heading>

      <Withdraw />
      <Box p="26px 16px" bg="#F2F2F2" borderRadius="8px">
        <HStack justify="space-between">
          <Text className="fs--16 fw--700">Available Balance</Text>
          <HStack>
            <Text>100 BTC</Text>
            <GrBitcoin color="#F7931A" />
          </HStack>
        </HStack>
      </Box>
      <Box
        h="86px"
        w={{ base: '100%', md: '50%' }}
        padding="5px"
        rounded="6px"
        mt="35px"
        border="1px solid grey"
      >
        <Text mt="1rem" className="fs--12">
          amount
        </Text>
        <Input
          className="fs--16"
          type="number"
          variant="unstyled"
          placeholder="0000"
        />
      </Box>
      <HStack
        py="40px"
        mt="35px"
        borderRadius="8px"
        bg="#F8F8F9"
        justify="space-around"
      >
        <Box>
          <Text className="fs--12">Receive Amount</Text>
          <Text className="fs--30 fw--700">0.000000200</Text>
        </Box>
        <Button w="122px">Withdraw</Button>
      </HStack>
      <Divider h="3px" py="1rem" />
      <HStack justify="space-between" my="1.2rem">
        <Heading className="fs--24 fw--700">Recent Withdraws</Heading>
        <Text className="fs--14" color="primary.main">
          Withdrawal hasn’t arrived?
        </Text>
      </HStack>

      <DataTable columns={withdrawColumns} data={withDraw} />
      {withDraw.length === 0 && <Center my="2rem">No Data</Center>}
    </>
  )
}

function Withdraw() {
  return (
    <>
      <Box display={{ base: 'block', md: 'flex' }} gap="8px">
        <Box flex={0.5}>
          <P2PCurrency />
        </Box>
        <Box flex={0.5}>
          <Box
            h="86px"
            padding="5px"
            rounded="6px"
            mt="35px"
            border="1px solid grey"
          >
            <Text mt="1rem" className="fs--12">
              Withdraw to
            </Text>
            <Input
              className="fs--16"
              variant="unstyled"
              placeholder="Enter Address"
            />
          </Box>
        </Box>
      </Box>
      <Box display={{ base: 'block', md: 'flex' }}>
        <Box flex={0.5}>
          <P2PNetwork />
        </Box>
      </Box>
      <Divider my="2rem" h="2px" />
    </>
  )
}
const columnHelper = createColumnHelper<IWithDraw>()
const withdrawColumns = [
  columnHelper.accessor('time', {
    cell: info => <Text>{info?.getValue()}</Text>,
    header: 'Time',
  }),
  columnHelper.accessor('currency', {
    cell: info => info.getValue(),

    header: 'Currency',
  }),
  columnHelper.accessor('receiveAmount', {
    cell: info => info.getValue(),
    header: 'Receive Amount',
  }),
  columnHelper.accessor('address', {
    cell: info => info.getValue(),
    header: 'Address',
  }),
  columnHelper.accessor('network', {
    cell: info => info.getValue(),
    header: 'Network',
  }),
  columnHelper.accessor('status', {
    cell: info => info.getValue(),
    header: 'Status',
  }),
  columnHelper.accessor('status', {
    cell: info => info.getValue(),
    header: 'Operation',
  }),
]
