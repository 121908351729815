import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  HStack,
  Image,
  Progress,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react'
import { CiLocationOn } from 'react-icons/ci'
import { Link, useNavigate } from 'react-router-dom'
import { Property } from 'utils/types/property.type'
import { useIsLogin } from 'hooks/index'
import { currencyFormat } from 'utils/helper'
import { MyEarnedAmount, MyInvestmentIcon } from 'assets/icons'
import { MyInvestment } from 'utils/types/dashboard.type'

interface Props {
  property: Property
  isRecent?: boolean
  myInvestment?: MyInvestment
}

export default function PropertyCard({
  property,
  isRecent,
  myInvestment,
}: Props) {
  const navigate = useNavigate()
  const isLogin = useIsLogin()
  return (
    <Card>
      <CardHeader>
        <Image
          rounded="5px"
          w="100%"
          objectFit="cover"
          maxH="220px"
          src={
            property?.imageThumbnail || 'https://via.placeholder.com/345x220'
          }
        />
      </CardHeader>
      <CardBody py="0">
        <Heading as="h4" fontSize="20px">
          {property?.title}
        </Heading>

        <Text as={Flex} my="15px" fontSize="16px" alignItems="center" gap="5px">
          <Flex>
            <CiLocationOn fontSize="18px" />
          </Flex>
          <Text textAlign="left" noOfLines={1} m="0">
            {property?.location}
          </Text>
        </Text>
        {!isRecent && (
          <>
            <Flex justify="space-between" flexWrap="wrap" gap="10px" mt="25px">
              <Text as="span">Construction</Text>
              <Text as="strong" color="gold.500">
                {currencyFormat(property?.fundRaised)}%
              </Text>
              <Progress
                value={property?.arv}
                colorScheme="gold"
                size="sm"
                w="100%"
                rounded="22px"
              />
            </Flex>
            <Flex justify="space-between" flexWrap="wrap" gap="10px" mt="25px">
              <Text as="span">Fund Raised</Text>
              <Text as="span">
                <Text as="strong" color="primary.500">
                  {currencyFormat(property?.fundRaised)} /{' '}
                </Text>
                {currencyFormat(property?.fundRequired)}
              </Text>
              <Progress
                value={property?.arv}
                colorScheme="primary"
                size="sm"
                w="100%"
                rounded="22px"
              />
            </Flex>
            <HStack
              mt="25px"
              spacing="14px"
              bg="gray.50"
              border="1px solid #EBEEEF"
              p="16px 20px"
              rounded="5px"
            >
              <Stats
                label="Value"
                value={currencyFormat(property?.fundRequired)}
              />
              <Stats label="APR" value={`${property?.APR} %`} />
              <Stats label="ARV" value={`${property?.arv?.toFixed(2)} %`} />
              <Stats
                label="Term"
                value={`${property?.term}${property?.termUnit
                  ?.charAt(0)
                  .toUpperCase()}`}
              />
            </HStack>
          </>
        )}
        {myInvestment && (
          <Box>
            <Text mt="30px" mb="20px" className="fs--20 fw--500">
              Your Ownership Stats
            </Text>
            <Flex justifyContent="space-between" mb="15px">
              <Flex as={Text} gap="8px" alignItems="center">
                <MyInvestmentIcon /> Your Investment{' '}
              </Flex>
              <Text as="strong">{currencyFormat(myInvestment?.amount)}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Flex as={Text} gap="8px" alignItems="center">
                <MyEarnedAmount />
                Earned Amount
              </Flex>{' '}
              <Text as="strong">
                {currencyFormat(myInvestment?.totalRewardEarned)}
              </Text>
            </Flex>
          </Box>
        )}
      </CardBody>
      <CardFooter as={HStack} spacing="15px">
        <Button
          as={Link}
          to={`/property/${property?.id}`}
          w="100%"
          variant="outline"
        >
          View Detail
        </Button>
        <Button
          w="100%"
          onClick={() =>
            isLogin ? navigate(`/property/${property?.id}`) : navigate('/login')
          }
        >
          Invest Now
        </Button>
      </CardFooter>
    </Card>
  )
}

function Stats({ label, value }: { label: string; value?: number | string }) {
  return (
    <Box textAlign="center" flex={1}>
      <Text as="span">{label}</Text>
      <Text mt={4} fontWeight="500" whiteSpace="nowrap">
        {value}
      </Text>
    </Box>
  )
}
