/* eslint-disable react/no-children-prop */
import React, { useState } from 'react'
import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Flex,
  Grid,
  Heading,
  HStack,
  // Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
} from '@chakra-ui/react'
import PropertyCard from 'components/propertyCard'
import ListingBg from 'assets/images/listing-bg.png'
// import NoFound from 'assets/images/no-property.png'
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons'
import Footer from 'components/footer'
import { useGetAllListings } from 'utils/apis/property.api'
import { useLocation, useNavigate } from 'react-router-dom'
import { IoCloseCircle } from 'react-icons/io5'
import Header from 'components/header'

export default function Listings() {
  const locations = useLocation()
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState(
    locations?.search?.split('=')[1],
  )
  const [isResidential, setIsResidential] = useState<boolean>()
  const [isCommercial, setIsCommercial] = useState<boolean>()

  const { data: listings, fetchNextPage } = useGetAllListings(
    10,
    `${searchValue ? `&search=${searchValue}` : ''}${
      isResidential ? `&filterIsResidential=${isResidential}` : ''
    }${isCommercial ? `&filterIsCommercial=${isCommercial}` : ''}`,
  )

  const handleClearFilters = (type: string) => {
    if (type === 'search') {
      setSearchValue('')
      navigate('/listings')
    } else if (type === 'isCommercial') setIsCommercial(false)
    else if (type === 'isResidential') setIsResidential(false)
    else if (type === 'all') {
      navigate('/listings')
      setSearchValue('')
      setIsCommercial(false)
      setIsResidential(false)
    }
  }

  return (
    <>
      <Header />
      <Box
        as="section"
        bg={`url(${ListingBg}) no-repeat top left,  linear-gradient(180deg, #F1E1FF -49.66%, rgba(243, 228, 255, 0) 100%)`}
        p="100px 0 140px"
        textAlign="center"
        color="white"
      >
        <Container maxW="1280px">
          <Heading as="h1" fontSize="40px" mx="auto" maxW="567px">
            Deals that are available for investments
          </Heading>
        </Container>
      </Box>
      <Container maxW="1280px" p="25px 0 100px">
        <Heading as="h2" mb="20px">
          Listed Opportunities
        </Heading>
        <Flex flexWrap="wrap" gap="20px" mb="20px">
          <InputGroup maxW="230px" bg="white">
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              focusBorderColor="primary.500"
              type="text"
              placeholder="Search..."
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </InputGroup>
          <Menu>
            <MenuButton
              w="225px"
              bg="white"
              borderColor="gray.200"
              textAlign="left"
              as={Button}
              variant="outline"
              rightIcon={<ChevronDownIcon />}
            >
              Add Filters
            </MenuButton>
            <MenuList>
              <MenuItem>
                <Checkbox
                  isChecked={isCommercial}
                  onChange={e => setIsCommercial(e.target.checked)}
                >
                  Commercial
                </Checkbox>
              </MenuItem>
              <MenuItem>
                <Checkbox
                  isChecked={isResidential}
                  onChange={e => setIsResidential(e.target.checked)}
                >
                  Residential
                </Checkbox>
              </MenuItem>
            </MenuList>
          </Menu>
          <Button onClick={() => handleClearFilters('all')}>
            Clear Filter
          </Button>
        </Flex>
        <HStack spacing={4} mb="20px">
          {searchValue && (
            <Tag size="lg" rounded="5px">
              <TagLabel>{searchValue}</TagLabel>
              <TagRightIcon
                onClick={() => handleClearFilters('search')}
                as={IoCloseCircle}
              />
            </Tag>
          )}
          {isCommercial && (
            <Tag size="lg" rounded="5px">
              <TagLabel>Commercial</TagLabel>
              <TagRightIcon
                onClick={() => handleClearFilters('isCommercial')}
                as={IoCloseCircle}
              />
            </Tag>
          )}
          {isResidential && (
            <Tag size="lg" rounded="5px">
              <TagLabel>Residential</TagLabel>
              <TagRightIcon
                onClick={() => handleClearFilters('isResidential')}
                as={IoCloseCircle}
              />
            </Tag>
          )}
        </HStack>
        <Grid
          gridTemplateColumns="repeat(auto-fill, minmax(340px, 1fr))"
          gap={{ base: '15px', md: '25px' }}
        >
          {listings?.pages?.map(page =>
            page?.map(item => <PropertyCard key={item?.id} property={item} />),
          )}
        </Grid>
        {listings?.pages?.[0]?.length === 0 && (
          <Center textAlign="center">
            <Box>
              {/* <Image src={NoFound} /> */}
              <Text fontWeight="600" mt="20px" fontSize="28px">
                Result Not Found
              </Text>
            </Box>
          </Center>
        )}
        {listings?.pages[listings?.pages?.length - 1]?.length === 10 && (
          <Center mt="30px">
            <Button onClick={() => fetchNextPage()} size="lg">
              Load More
            </Button>
          </Center>
        )}
      </Container>
      <Footer />
    </>
  )
}
