import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { SellerAccountOrders } from 'utils/types/global'
import { Text } from '@chakra-ui/react'
export const SellerOrderData = [
  {
    orderId: 'Buy,652asXe0...283330nM',
    orderStatus: 'Canceled',
    orderAmmount: '400 USDT',
    orderPrice: '264PKR/USDT',
    orderQuantity: '133.6 USDT',
    orderTime: '2022-11-11 10:47:46',
  },
  {
    orderId: 'Sell,652asXe0...283330nM',
    orderStatus: 'Canceled',
    orderAmmount: '400 USDT',
    orderPrice: '264PKR/USDT',
    orderQuantity: '133.6 USDT',
    orderTime: '2022-11-11 10:47:46',
  },
  {
    orderId: 'Buy,652asXe0...283330nM',
    orderStatus: 'Canceled',
    orderAmmount: '400 USDT',
    orderPrice: '264PKR/USDT',
    orderQuantity: '133.6 USDT',
    orderTime: '2022-11-11 10:47:46',
  },
  {
    orderId: 'Sell,652asXe0...283330nM',
    orderStatus: 'Canceled',
    orderAmmount: '400 USDT',
    orderPrice: '264PKR/USDT',
    orderQuantity: '133.6 USDT',
    orderTime: '2022-11-11 10:47:46',
  },
  {
    orderId: 'Buy,652asXe0...283330nM',
    orderStatus: 'Canceled',
    orderAmmount: '400 USDT',
    orderPrice: '264PKR/USDT',
    orderQuantity: '133.6 USDT',
    orderTime: '2022-11-11 10:47:46',
  },
  {
    orderId: 'Sell,652asXe0...283330nM',
    orderStatus: 'Canceled',
    orderAmmount: '400 USDT',
    orderPrice: '264PKR/USDT',
    orderQuantity: '133.6 USDT',
    orderTime: '2022-11-11 10:47:46',
  },
  {
    orderId: 'Buy,652asXe0...283330nM',
    orderStatus: 'Canceled',
    orderAmmount: '400 USDT',
    orderPrice: '264PKR/USDT',
    orderQuantity: '133.6 USDT',
    orderTime: '2022-11-11 10:47:46',
  },
  {
    orderId: 'Sell,652asXe0...283330nM',
    orderStatus: 'Canceled',
    orderAmmount: '400 USDT',
    orderPrice: '264PKR/USDT',
    orderQuantity: '133.6 USDT',
    orderTime: '2022-11-11 10:47:46',
  },
]
const columnHelper = createColumnHelper<SellerAccountOrders>()
export const SellerOrderCloumnsData = [
  columnHelper.accessor('orderId', {
    cell: info => <Text>{info.getValue()}</Text>,
    header: 'Order Id',
  }),
  columnHelper.accessor('orderStatus', {
    cell: info => <Text>{info.getValue()}</Text>,
    header: 'Status',
  }),
  columnHelper.accessor('orderAmmount', {
    cell: info => <Text>{info.getValue()}</Text>,
    header: 'Ammount',
  }),
  columnHelper.accessor('orderPrice', {
    cell: info => <Text>{info.getValue()}</Text>,
    header: 'Price',
  }),
  columnHelper.accessor('orderQuantity', {
    cell: info => <Text>{info.getValue()}</Text>,
    header: 'Quantity',
  }),
  columnHelper.accessor('orderTime', {
    cell: info => <Text>{info.getValue()}</Text>,
    header: 'Time/Posted by',
  }),
]
