import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { InvestNow, InvestNowRes, Property } from 'utils/types/property.type'
import { client } from './api-client'

export const useGetAllListings = (limit = 10, query?: string) =>
  useInfiniteQuery<Property[], Error>(
    ['getAllListings', limit, query],
    ({ pageParam = 0 }) =>
      client(
        `project/getProjects?offset=${pageParam}&limit=${limit}${
          query ? query : ''
        }`,
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length * limit
        return nextPage
      },
    },
  )

export const useGetPropertyDetails = (id?: string) =>
  useQuery<Property, Error>(
    ['getPropertyDetails', id],
    () => client(`project/getProjectDetail/${id}`),
    {
      enabled: !!id,
    },
  )

export const useInvestNow = () => {
  const queryClient = useQueryClient()
  return useMutation<InvestNowRes, Error, InvestNow>(
    data =>
      client('investment/create', {
        data: {
          areaMeterSquare: 0,
          ...data,
        },
      }),
    {
      onSuccess(data, { projectId }) {
        queryClient.invalidateQueries(['getPropertyDetails', projectId])
        queryClient.invalidateQueries(['getAllListings'])
        queryClient.invalidateQueries(['userProfile'])
        queryClient.invalidateQueries(['getMyInvestments'])
        queryClient.invalidateQueries(['getTransactions'])
      },
    },
  )
}
