import { Menu, SocialLinks, FooterLink } from './types/global'
import fb from '../assets/icons/footer/fb.svg'
import insta from '../assets/icons/footer/insta.svg'
import github from '../assets/icons/footer/github.svg'
import twitter from '../assets/icons/footer/twitter.svg'

export const menu: Menu[] = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'P2P',
    path: '/p2p',
  },
  {
    label: 'Deals',
    path: '/listings',
  },
  {
    label: 'How it works',
    path: '/#how-it-works',
  },
  {
    label: 'Socialize',
    path: '/',
  },
]

export const footerLinks: {
  services: FooterLink[]
  support: FooterLink[]
  company: FooterLink[]
  marketData: FooterLink[]
} = {
  services: [
    { label: 'Download App', url: 'asasd' },
    { label: 'Trading Fees', url: 'asasd' },
    { label: 'Markets', url: 'asasd' },
    { label: 'News', url: 'asasd' },
    { label: 'Listing Application', url: 'asasd' },
    { label: 'P2P Merchant Appliation', url: 'asasd' },
    { label: 'Signal Trading', url: 'asasd' },
  ],
  support: [
    { label: 'Beginner’s Guide', url: 'asasd' },
    { label: 'Help Center', url: 'asasd' },
    { label: 'Feedback', url: 'asasd' },
    { label: 'API Documentation', url: 'asasd' },
    { label: 'Extsy Verify', url: 'asasd' },
  ],
  company: [
    { label: 'About Us', url: 'asasd' },
    { label: 'Work With Us', url: 'asasd' },
    { label: 'Extsy Affiliate Program', url: 'asasd' },
    { label: '100% Proof of Reserves', url: 'asasd' },
    { label: 'Announcement Center', url: 'asasd' },
    { label: 'Blog', url: 'asasd' },
  ],
  marketData: [
    { label: 'Beginner’s Guide', url: 'asasd' },
    { label: 'Help Center', url: 'asasd' },
    { label: 'Feedback', url: 'asasd' },
    { label: 'API Documentation', url: 'asasd' },
    { label: 'Extsy Verify', url: 'asasd' },
  ],
}

export const socialLinks: SocialLinks[] = [
  { url: 'https://twitter.com/', icon: twitter },
  { url: 'https://facebook.com/', icon: fb },
  { url: 'https://instagram.com/', icon: insta },
  { url: 'https://github.com/', icon: github },
]
