import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Center,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useVerifyEmail, useVerifyPhoneNumber } from 'utils/apis/user.api'
import { InfoIcon } from '@chakra-ui/icons'
import Header from 'components/header'

export default function OTPVerification() {
  const { state } = useLocation()
  const navigate = useNavigate()

  const [otp, setOtp] = useState('')
  const {
    mutate: verifyNumber,
    data: numberRes,
    isLoading: numberLoading,
  } = useVerifyPhoneNumber()
  const { mutate: verifyEmail, isLoading, isSuccess, data } = useVerifyEmail()

  useEffect(() => {
    if (numberRes) {
      setOtp('')
      navigate('/email-verification', {
        state: {
          email: numberRes?.userData?.email,
        },
      })
    }
  }, [numberRes])

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem('token', data?.token?.access_token?.split(' ')[1])
      navigate('/profile')
    }
  }, [isSuccess])

  const handleOtpVerify = () => {
    if (state?.phoneNo) {
      verifyNumber({
        phoneNo: state?.phoneNo,
        otp,
      })
    } else if (state?.email) {
      verifyEmail({
        email: state?.email,
        otp,
      })
    }
  }
  return (
    <>
      <Header />
      <Center minH="calc(100vh - 82px)">
        <Box maxW="500px">
          <Heading fontSize="30px">
            {state?.phoneNo ? 'Number Verification' : 'Email Verification'}
          </Heading>
          <Text m="15px 0 25px">
            Please enter the 6-digit verification code sent to{' '}
            {state?.phoneNo || state?.email}. The code is valid for 30 minutes.
          </Text>
          <FormLabel>
            {state?.phoneNo ? 'Number' : 'Email'} Verification Code
          </FormLabel>
          <InputGroup>
            <Input onChange={e => setOtp(e.target.value)} />

            <InputRightElement
              // eslint-disable-next-line react/no-children-prop
              children={<InfoIcon color="black.500" />}
            />
          </InputGroup>
          <Text m="25px 0 35px">
            Didn’t receive the code? <Button variant="link">Resend</Button>
          </Text>
          <Button
            isLoading={numberLoading || isLoading}
            w="100%"
            onClick={handleOtpVerify}
          >
            Submit
          </Button>
        </Box>
      </Center>
    </>
  )
}
