import React from 'react'
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Image,
  Link,
  Text,
  Heading,
} from '@chakra-ui/react'
import './footer.scss'
import Logo from 'assets/images/logo.svg'
import { socialLinks, footerLinks } from 'utils/const'
import { FooterLink } from 'utils/types/global'
import FooterBanner from 'assets/images/register-bg.png'
export default function Footer() {
  return (
    <Box bg="white" as="footer">
      <Box
        className="footer-banner"
        bg={`url(${FooterBanner}) no-repeat `}
        bgColor="primary.500"
        textAlign="center"
        p="80px 20px"
      >
        <Heading as="h3" color="white" className="fs--30 fw--700">
          Looking for best investment opportunity?
        </Heading>
        <Text color="white" className="fs--20 fw--400" p="0 0.5rem 1.5rem">
          Don’t waste time contact and invest now and start earning.
        </Text>
        <Button bg="white" variant="outline" color="primary.500" size="lg">
          Contact Us
        </Button>
      </Box>
      <Container maxW="1280px">
        <Flex wrap="wrap" mt="2.2rem" pb="1.5rem">
          <Box flex="0.3">
            <Image src={Logo} alt="Logo" />
            <Text className="fs--12" color="#52525B" p="1rem 3rem 1rem 0">
              Lorem ipsum dolor sit amet consectetur. Aliquet congue amet donec
              eu. Pellentesque sodales id.
            </Text>
            <Flex gap="7px">
              {socialLinks.map(({ url, icon }) => (
                <Box
                  as={Link}
                  href={url}
                  isExternal
                  key={url}
                  _hover={{
                    bg: 'primary.500',
                  }}
                  className={`socialLink `}
                >
                  <Image src={icon} />
                </Box>
              ))}
            </Flex>
          </Box>
          {Object.entries(footerLinks).map(item => (
            <FooterLinks key={item[0]} item={item} />
          ))}
        </Flex>
      </Container>
      <Divider />
      <Text
        textAlign="center"
        color="#71717A"
        fontSize="12.6"
        fontWeight="400"
        py="1.5rem"
      >
        © Copyright {new Date().getFullYear()}, All Rights Reserved by Extsy
      </Text>
    </Box>
  )
}

type Props = {
  item: [string, FooterLink[]]
}

function FooterLinks({ item }: Props) {
  return (
    <Box flex="0.17">
      <Text
        textTransform="capitalize"
        color="primary.500"
        className="footer-heading"
      >
        {item[0]?.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')}
      </Text>
      {item[1].map(({ label, url }) => (
        <Link href={url} isExternal className="footer-link" key={label}>
          {label}
        </Link>
      ))}
    </Box>
  )
}
