import React from 'react'
import {
  Container,
  Grid,
  Heading,
  Box,
  Button,
  Text,
  Image,
} from '@chakra-ui/react'
import QRCode from 'assets/images/qr-code.png'

interface Props {
  title: string
  children: JSX.Element
}

export default function UserAuth({ title, children }: Props) {
  return (
    <Container maxW="1280px" py="30px">
      <Grid
        gridTemplateColumns="1fr 1fr"
        alignItems="center"
        minH="calc(100vh - 82px)"
      >
        <Box maxW="420px">
          <Heading mb="35px">{title}</Heading>
          {children}
        </Box>
        <Box textAlign="center">
          <Image mx="auto" width="221" height="221" src={QRCode} />
          <Heading my="15px" className="fs--30 fw--700" as="h2">
            Log in with QR code
          </Heading>
          <Text fontSize="14px">
            Scan this code with the{' '}
            <Button className="fs--14 fw--400" as="a" href="#" variant="link">
              EXTSY mobile app
            </Button>{' '}
            to log in instantly.
          </Text>
        </Box>
      </Grid>
    </Container>
  )
}
