/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import {
  Control,
  Controller,
  FieldValues,
  Path,
  UseFormSetValue,
} from 'react-hook-form'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

export type PhoneNumberInputProps<TFormValues extends FieldValues> = {
  errorsMessage?: string
  name: Path<TFormValues>
  isError: boolean
  control: Control<TFormValues>
  setValue: UseFormSetValue<TFormValues>
}

export const PhoneNumberInput = <TFormValues extends Record<string, any>>({
  name,
  isError,
  errorsMessage,
  control,
  setValue,
}: PhoneNumberInputProps<TFormValues>) => {
  const [isValidPhone, setIsValidPhone] = useState(false)
  return (
    <FormControl isInvalid={isError} mb="20px">
      <FormLabel>Phone</FormLabel>
      <Controller
        control={control}
        name={name}
        rules={{
          required: 'Mobile Number required',
          validate: {
            isValid: () => isValidPhone || 'Enter valid Phone',
          },
        }}
        render={({ field: { ref, ...field } }) => (
          <ReactPhoneInput
            buttonStyle={{
              background: 'transparent',
              border: 'none',
            }}
            inputStyle={{
              width: '100%',
              border: isError ? '2px solid #ff0000' : '1px solid #E2E8F0',
              height: '40px',
            }}
            {...field}
            inputProps={{
              ref,
            }}
            enableSearch
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(value: any, country: any, e, formattedValue) => {
              setValue(name, value)
              const { format, dialCode } = country
              if (
                format?.length === formattedValue?.length &&
                (value.startsWith(dialCode) || dialCode.startsWith(value))
              ) {
                setIsValidPhone(true)
              } else {
                setIsValidPhone(false)
              }
            }}
            country={'gb'}
            countryCodeEditable={false}
          />
        )}
      />
      <FormErrorMessage>{errorsMessage}</FormErrorMessage>
    </FormControl>
  )
}
