import React from 'react'
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Text,
} from '@chakra-ui/react'
import BTC from 'assets/images/BTCICon.png'
import { ChevronDownIcon } from '@chakra-ui/icons'
export default function P2PCurrency() {
  return (
    <Flex
      padding="5px"
      direction="column"
      rounded="6px"
      border="1px solid grey"
      mt="35px"
    >
      <Text ml="5px" mb="10px">
        Currency
      </Text>
      <Menu matchWidth={true}>
        <MenuButton
          _active={{}}
          _hover={{}}
          bg="transparent"
          textAlign="start"
          color="black"
          as={Button}
          w="100%"
          rightIcon={<ChevronDownIcon />}
        >
          <Flex alignItems="center" className="fs--16 fw--400">
            <Image src={BTC} />
            <Text ml="15px">BTC</Text>
          </Flex>
        </MenuButton>
        <MenuList minWidth="inherit">
          <MenuItem minH="48px">
            <Image
              boxSize="2rem"
              borderRadius="full"
              src="https://placekitten.com/100/100"
              alt="Fluffybuns the destroyer"
              mr="12px"
            />
            <Text>BTC</Text>
          </MenuItem>
          <MenuItem minH="40px">
            <Image
              boxSize="2rem"
              borderRadius="full"
              src="https://placekitten.com/120/120"
              alt="Simon the pensive"
              mr="12px"
            />
            <Text>USDT</Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}
