import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Register from 'views/register'
import Login from 'views/login'
import Listings from 'views/listings'
import PropertyDetails from 'views/propertyDetails'
import Landing from 'views/landing'
import BuyerAccount from 'views/p2p/Buyer'
import PlaceOrder from 'components/mobileViewP2P/placeOrder'
import Profile from 'views/profile'
import OTPVerification from 'views/otpVerification'

const routes = [
  {
    path: '/',
    Component: Landing,
  },
  {
    path: '/listings',
    Component: Listings,
  },
  {
    path: '/property/:id',
    Component: PropertyDetails,
  },
  {
    path: '/register',
    Component: Register,
  },
  {
    path: '/login',
    Component: Login,
  },
  {
    path: '/p2p',
    Component: BuyerAccount,
  },
  {
    path: '/buy-mobile',
    Component: PlaceOrder,
  },
  {
    path: '/profile',
    Component: Profile,
  },
  {
    path: '/mobile-number-verification',
    Component: OTPVerification,
  },
  {
    path: '/email-verification',
    Component: OTPVerification,
  },
]

export default function AppRoutes() {
  return (
    <Routes>
      {routes?.map(({ path, Component }) => (
        <Route key={path} element={<Component />} path={path} />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
