import React, { useState } from 'react'
import {
  Button,
  Text,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

export default function P2PNetwork() {
  const [selectedNetwork, setSelectedNetwork] = useState<string>('')
  return (
    <Flex
      padding="5px"
      direction="column"
      rounded="6px"
      border="1px solid grey"
      mt="35px"
    >
      <Text ml="5px" mb="10px">
        Network
      </Text>
      <Menu matchWidth={true}>
        <MenuButton
          _active={{}}
          _hover={{}}
          bg="transparent"
          textAlign="start"
          color="black"
          w="100%"
          as={Button}
          px="4px"
          rightIcon={<ChevronDownIcon />}
        >
          {selectedNetwork ? selectedNetwork : 'select'}
        </MenuButton>
        <MenuList minWidth="inherit">
          {['ERC20', 'TRC20', 'BEP20', 'SQL', 'POLYGON'].map(data => (
            <MenuItem key={data} onClick={() => setSelectedNetwork(data)}>
              <Text color="muted">{data}</Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}
