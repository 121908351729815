import {
  Heading,
  HStack,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import DataTable from 'components/table'
import React from 'react'
import { BsCheckCircleFill } from 'react-icons/bs'
import { MdCancel } from 'react-icons/md'
import { p2pHistory } from 'utils/data/profile.data'
import { IP2PHistory } from 'utils/types/profile.type'

export default function P2PHistory() {
  return (
    <>
      <Heading className="fs--30 fw--700" mb="2rem">
        P2P History
      </Heading>
      <Tabs>
        <TabList flexWrap="wrap">
          <Tab>All</Tab>
          <Tab>Processing</Tab>
          <Tab>Complete</Tab>
          <Tab>Cancelled</Tab>
          <Tab>Appeal</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <DataTable columns={p2pHistoryCols} data={p2pHistory} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}
const columnHelper = createColumnHelper<IP2PHistory>()
const p2pHistoryCols = [
  columnHelper.accessor('orderID', {
    cell: info => <Text>{info.getValue()}</Text>,
    header: 'Order ID',
  }),
  columnHelper.accessor('status', {
    cell: info =>
      info.getValue() === 'Completed' ? (
        <HStack>
          <Text className="fs--14" color="#4ECA78">
            {info.getValue()}
          </Text>
          <BsCheckCircleFill color="#4ECA78" />
        </HStack>
      ) : (
        <HStack>
          <Text className="fs--14" color="#E84A4A">
            {info.getValue()}
          </Text>
          <MdCancel color="#E84A4A" fontSize="18px" />
        </HStack>
      ),
    header: 'Status',
  }),
  columnHelper.accessor('amount', {
    cell: info => info.getValue(),
    header: 'Amount',
  }),
  columnHelper.accessor('price', {
    cell: info => info.getValue(),
    header: 'Price',
  }),
  columnHelper.accessor('network', {
    cell: info => info.getValue(),
    header: 'Network',
  }),
  columnHelper.accessor('quantity', {
    cell: info => info.getValue(),
    header: 'Quantity',
  }),
  columnHelper.accessor('time', {
    cell: info => info.getValue(),
    header: 'Time/Posted by',
  }),
  columnHelper.accessor('time', {
    cell: () => (
      <Link className="fs--14" color="primary.main">
        View Order
      </Link>
    ),
    header: 'Action',
  }),
]
