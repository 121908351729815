import React, { useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  List,
  ListIcon,
  ListItem,
  Progress,
  Stat,
  StatHelpText,
  StatLabel,
  Tag,
  Text,
} from '@chakra-ui/react'
import PropertyBg from 'assets/images/property-bg.png'
import { CiLocationOn } from 'react-icons/ci'
import { RxDashboard } from 'react-icons/rx'
import { HiSquares2X2 } from 'react-icons/hi2'
import { BsCircleFill, BsRecordCircle } from 'react-icons/bs'
import { APRIcon } from 'assets/icons'
import Footer from 'components/footer'
import { useParams } from 'react-router-dom'
import { useGetPropertyDetails } from 'utils/apis/property.api'
import { currencyFormat } from 'utils/helper'
import * as DOMPurify from 'dompurify'
import Header from 'components/header'

export default function PropertyDetails() {
  const { id } = useParams()
  const [selectedArea, setSelectedArea] = useState(0)
  const { data: property } = useGetPropertyDetails(id)

  const leftArea =
    property &&
    Math.abs(Number(property?.totalArea - property?.areaLocked - selectedArea))
  console.log(
    property &&
      Math.abs(
        Number(property?.totalArea - property?.areaLocked - selectedArea),
      ),
    Math.abs(Number(selectedArea / 300)),
  )
  return (
    <>
      <Header />
      <Box
        as="section"
        bg={`url(${PropertyBg}) no-repeat center`}
        backgroundSize="cover"
        py="120px"
        textAlign="center"
      >
        <Container maxW="1280px">
          <Heading as="h1" fontSize="40px" mx="auto" maxW="567px" color="white">
            {property?.title}
          </Heading>
          <Text m="23px auto 0" maxW="820px" color="white">
            {property?.isResidential &&
              `Residential properties typically refer to properties that are used for living purposes, such as apartments, townhouses, or single-family homes. Residential properties is a good option for investors who are looking for a steady stream of income from rental payments.`}
            {property?.isCommercial &&
              `Industrial 
               typically refer to properties that are used for business purposes, such as office buildings, warehouses, or retail spaces. They are a good option for investors who are looking for higher rental income potential, as Industrial tenants often sign longer leases and can be more reliable in paying rent.`}
          </Text>
          <Text
            as={HStack}
            m="25px auto 0"
            fontSize="16px"
            spacing="10px"
            bg="white"
            rounded="6px"
            p="8px"
            w="max-content"
          >
            <Box as={Flex} bg="primary.50" rounded="4px" p="4px">
              <CiLocationOn
                color="var(--chakra-colors-primary-500)"
                fontSize="22px"
              />
            </Box>
            <Text>{property?.location}</Text>
          </Text>
        </Container>
      </Box>
      <Box as="section" py="100px">
        <Container maxW="1280px" as={Flex}>
          <Box maxW="280px" w="100%">
            <List spacing={3} className="StepProgress">
              <ListItem>
                <ListIcon as={BsRecordCircle} color="primary.500" />
                <Text as="strong" color="primary.500">
                  Project’s Details
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={BsCircleFill} color="primary.500" />
                <Text as="strong" color="primary.500">
                  Area
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={BsCircleFill} color="primary.500" />
                <Text as="strong" color="primary.500">
                  Area
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={BsCircleFill} color="primary.500" />
                <Text as="strong" color="primary.500">
                  Area
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={BsCircleFill} color="primary.500" />
                <Text as="strong" color="primary.500">
                  Area
                </Text>
              </ListItem>
            </List>
          </Box>
          <Box flex="1">
            <Heading
              as="h2"
              display="flex"
              alignItems="center"
              gap="25px"
              fontSize="40px"
            >
              Project’s details{' '}
              <Tag
                p="7px 15px"
                className="fs--12 fw--300"
                color="white"
                bg="success.500"
              >
                Open For Investment
              </Tag>
            </Heading>
            <Flex gap="18px" my="35px" wrap="wrap" justifyContent="center">
              <PropertyStats
                icon={<APRIcon />}
                label="APR"
                value={`${property?.APR}%`}
              />
              <PropertyStats
                icon={<APRIcon />}
                label="Investment Offering"
                value={currencyFormat(property?.fundRequired)}
              />
              <PropertyStats
                icon={<APRIcon />}
                label="Minimum Investment"
                value={currencyFormat(property?.minimumInvestment)}
              />
              <PropertyStats
                icon={<APRIcon />}
                label="Term"
                value={`${property?.term} ${property?.termUnit}`}
              />
            </Flex>
            <Flex justify="space-between" flexWrap="wrap" gap="18px" my="35px">
              <Heading className="fs--20 fw--600" as="h4">
                Fund Raised
              </Heading>
              <Text as="span">
                <Text as="strong" color="primary.500">
                  {currencyFormat(property?.fundRaised)}
                </Text>
                / {currencyFormat(property?.fundRequired)}
              </Text>
              <Progress
                value={10}
                colorScheme="primary"
                size="lg"
                w="100%"
                rounded="22px"
              />
            </Flex>
            <Heading fontSize="40px" as="h3">
              Area
            </Heading>
            <Flex
              rounded="6px"
              p="20px"
              border="1px solid #EBEEEF"
              bg="white"
              my="35px"
              justifyContent="space-between"
              textAlign="center"
            >
              <Stat w="100%" maxW="max-content">
                <StatLabel className="fs--20 fw--700" mb="10px">
                  Total Area
                </StatLabel>
                <RxDashboard fontSize="24px" />
                <StatHelpText mt="10px">
                  {property?.totalArea} Sq Ft
                </StatHelpText>
              </Stat>
              <Stat w="100%" maxW="max-content">
                <StatLabel className="fs--20 fw--700" mb="10px">
                  Area Locked
                </StatLabel>
                <HiSquares2X2
                  color="var(--chakra-colors-primary-100)"
                  fontSize="24px"
                />
                <StatHelpText mt="10px">
                  {property?.areaLocked} Sq Ft
                </StatHelpText>
              </Stat>
              <Stat w="100%" maxW="max-content">
                <StatLabel className="fs--20 fw--700" mb="10px">
                  Area Left
                </StatLabel>
                <RxDashboard fontSize="24px" />
                <StatHelpText mt="10px">{leftArea} Sq Ft</StatHelpText>
              </Stat>
              <Stat w="100%" maxW="max-content">
                <StatLabel className="fs--20 fw--700" mb="10px">
                  Selected Area
                </StatLabel>
                <HiSquares2X2
                  color="var(--chakra-colors-primary-500)"
                  fontSize="24px"
                />
                <StatHelpText mt="10px">{selectedArea} Sq Ft</StatHelpText>
              </Stat>
            </Flex>
            <Heading fontSize="20px" as="h4">
              Select area
            </Heading>
            <Editable
              rounded="6px"
              m="16px 0 30px"
              bg="white"
              border="1px solid #EBEEEF"
              p="19px 16px"
              placeholder="Minimum 100 Sq Ft"
            >
              <Text fontSize="12px">Enter area</Text>
              <EditablePreview color="secondaryText" />
              <EditableInput
                onChange={e => setSelectedArea(Number(e.target.value))}
                css={`
                  :focus-visible {
                    box-shadow: none;
                  }
                `}
                type="number"
              />
            </Editable>
            <Heading fontSize="20px" as="h4">
              Selected Area Preview
            </Heading>
            {property && leftArea ? (
              <Flex
                rounded="6px"
                p="25px"
                flexWrap="wrap"
                border="1px solid #EBEEEF"
                bg="white"
                gap="8px"
                my="35px"
              >
                {[...Array((property?.areaLocked / 300).toFixed(0))].map(
                  item => (
                    <Box
                      rounded="2px"
                      key={item}
                      w="18px"
                      h="18px"
                      bg="primary.100"
                    ></Box>
                  ),
                )}
                {[...Array((selectedArea / 300).toFixed(0))].map(item => (
                  <Box
                    rounded="2px"
                    key={item}
                    w="18px"
                    h="18px"
                    bg="primary.500"
                  ></Box>
                ))}
                {[...Array((leftArea / 300).toFixed(0))].map(item => (
                  <Box
                    rounded="2px"
                    key={item}
                    w="18px"
                    h="18px"
                    border="1.5px solid #7B7382"
                  ></Box>
                ))}
              </Flex>
            ) : null}
            <Flex
              className="fs--18 fw--500"
              my="25px"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              <Text flex="1">Price Per Sq Ft</Text>
              <Text>{currencyFormat(property?.pricePerUnit)}</Text>
              <Divider borderColor="gray.200" my="20px" />
              <Text>Selected Area</Text>
              <Text>{selectedArea} Sq Ft</Text>
            </Flex>
            <Card mb="20px">
              <CardBody>
                <Heading fontSize="20px" as="h4">
                  Investment Terms
                </Heading>
                <Grid
                  mt="25px"
                  gridTemplateColumns="1fr max-content"
                  gap="20px"
                >
                  <Text>Contract address</Text>
                  <Text>0x000......000</Text>
                  <Text>Chain</Text>
                  <Text>Binance Smart Chain</Text>
                  <Text>Investment Token</Text>
                  <Text>USDT</Text>
                  <Text>Gas Fee</Text>
                  <Text>BNB (Paid by user)</Text>
                </Grid>
              </CardBody>
            </Card>
            <Flex
              justifyContent="space-between"
              border="1px solid #EBEEEF"
              p="25px 30px"
              align="center"
            >
              <Stat w="100%" maxW="max-content">
                <StatLabel mb="10px">Total Price</StatLabel>
                <StatHelpText
                  className="fs--20 fw--700"
                  m="0"
                  color="primary.500"
                >
                  40,000 $
                </StatHelpText>
              </Stat>
              <Button>Invest Now</Button>
            </Flex>
            <Heading fontSize="40px" as="h3" m="35px 0 25px">
              More about {property?.title}
            </Heading>
            <Box
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(property?.description || ''),
              }}
            />
            <Heading as="h3" fontSize="40px" my="35px">
              Location on map
            </Heading>
            {/* map and images */}
            <Flex flexWrap="wrap" gap="15px">
              <Image
                src="https://via.placeholder.com/902x460"
                mb="55px"
                alt="image"
              />
              {property?.media?.map(src => (
                <Image
                  key={src || 'https://via.placeholder.com/345x316'}
                  src={src}
                  alt="media image"
                />
              ))}
            </Flex>
            <Heading as="h3" fontSize="40px" my="35px">
              Strategy
            </Heading>
            <Accordion allowToggle>
              <AccordionItem border="1px solid #EBEEEF" p="10px" rounded="6px">
                <AccordionButton>
                  <Heading
                    py="15px"
                    as="h5"
                    fontSize="18px"
                    flex="1"
                    textAlign="left"
                  >
                    What are we planning
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} borderTop="1px solid #EBEEEF">
                  <Box
                    p="0 10px"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(property?.strategy || ''),
                    }}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  )
}

function PropertyStats({
  icon,
  label,
  value,
}: {
  icon: JSX.Element
  label: string
  value: string
}) {
  return (
    <Box
      bgColor="blackAlpha.100"
      p="25px 10px"
      textAlign="center"
      rounded="5px"
      w="calc((100% / 4) - 14px)"
    >
      {icon}
      <br />
      <Text as="span">{label}</Text>
      <br />
      <Text as="strong" className="fs--20 fw--500">
        {value}
      </Text>
    </Box>
  )
}
