export default {
  fonts: {
    body: 'HarmonyOS Sans',
    heading: 'HarmonyOS Sans',
    label: 'HarmonyOS Sans',
  },
  colors: {
    primary: {
      main: '#924ece',
      50: '#f0e7f8',
      100: '#d8c3ed',
      200: '#bf9be2',
      300: '#a670d7',
      400: '#924ece',
      500: '#7f29c4',
      600: '#7523be',
      700: '#6819b6',
      800: '#5c10af',
      900: '#4900a1',
    },
    secondary: '#E7CEFC',
    text: '#7B7382',
    background: '#FAFAFA',
    error: '#E84A4A',
    success: {
      400: '#85CB33',
      500: '#85CB33',
    },
    gold: {
      400: '#CAA04E',
      500: '#CAA04E',
    },
    heading: '#33263E',
    secondaryText: '#7B7382',
  },
  components: {
    Text: {
      baseStyle: {
        color: 'text',
      },
    },
    Heading: {
      baseStyle: {
        color: 'heading',
      },
    },
    Button: {
      baseStyle: {
        fontWeight: '500',
        border: '1px solid transparent',
        _hover: {
          color: 'primary.500',
        },
      },
      variants: {
        outline: {
          color: '#7B7382',
          _hover: {
            bg: 'primary.500',
            color: 'white',
            borderColor: 'primary.500',
          },
        },
        solid: {
          _hover: {
            bg: 'transparent',
            borderColor: 'primary.500',
          },
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: 'background',
        color: 'text',
        fontWeight: '400',
      },
      a: {
        color: 'primary.500',
      },
    },
  },
}
