import React from 'react'
import {
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Card,
  Box,
  Image,
  UnorderedList,
  ListItem,
  Checkbox,
  Button,
} from '@chakra-ui/react'
import { AiOutlineGoogle } from 'react-icons/ai'
import { IDeposit } from 'utils/types/profile.type'
import { createColumnHelper } from '@tanstack/react-table'
import { depositData } from 'utils/data/profile.data'
import DataTable from 'components/table'
import copy from 'assets/images/CopyCode.png'
import Qr from 'assets/images/QrCode.png'
import P2PCurrency from 'components/p2pCurrency'
import P2PNetwork from 'components/P2PNetwork'

export default function Deposit() {
  const {
    isOpen: checkFaq,
    onOpen: openFaq,
    onClose: closeFaq,
  } = useDisclosure()
  const {
    isOpen: buyCryto,
    onOpen: openBuyCrypto,
    onClose: closeBuyCrypto,
  } = useDisclosure()

  return (
    <>
      <Box w="100%">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          w={{ base: '100%', md: '60%' }}
        >
          <Text className="fs--30 fw--700" onClick={openFaq}>
            Deposit
          </Text>
          <Flex className="fs--14 fw--400" gap="12px">
            <Text cursor="pointer" onClick={openBuyCrypto} color="primary.main">
              Buy Crypto
            </Text>
            <Text>Tutorial</Text>
          </Flex>
        </Flex>
        <Box w={{ base: '100%', md: '50%' }}>
          <P2PCurrency />
          <P2PNetwork />
        </Box>
        <>
          <Flex
            width="40%"
            p="10px"
            className="fs--12 fw--700"
            color="primary.main"
            bg="currencySelect"
            mt="15px"
            rounded="6px"
          >
            <Text>
              You have selected SOL, please ensure that you choose the same
              network on the withdrawal platform.
            </Text>
          </Flex>
          <Flex mt="30px" direction="column" w="70%">
            <Text>Address</Text>
            <Box
              display={{ base: 'block', md: 'flex' }}
              alignItems="center"
              justifyContent="space-between"
              gap="8px"
            >
              <Text>AyUGCzrZrWJLw1UiKLFxW3dirboPcEaQ3Wv7TdZHDBBH</Text>
              <Flex>
                <Image mr="15px" src={copy} />
                <Image src={Qr} />
              </Flex>
            </Box>
          </Flex>
          <Flex
            mt="30px"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            w="70%"
          >
            <Text color="muted">Deposite Account</Text>
            <Text display="flex" color="black">
              Standard Futures Account <Text color="primary.main">Modify</Text>
            </Text>
          </Flex>
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            w="70%"
          >
            <Text>Minimum Deposit</Text>
            <Text>0.9 USDT</Text>
          </Flex>
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            w="70%"
          >
            <Text>Est.Arrival</Text>
            <Text>200network confirmations</Text>
          </Flex>
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            w="70%"
          >
            <Text>Est.Unlock</Text>
            <Text>300network confirmations</Text>
          </Flex>
          <Flex>
            <UnorderedList>
              <ListItem>
                Send only USDT to this deposit address. Other assets will be
                lost and cannot be retrieved.
              </ListItem>
              <ListItem>
                The minimum deposit amount is 0.9 USDT. Any deposit less than
                this amount cannot be credited or returned.
              </ListItem>
              <ListItem>
                You&apos;ll be notified via SMS&apos; email&apos; App push&apos;
                or other ways once the deposit is completed.
              </ListItem>
              <ListItem>
                If you encounter any deposit problems, please go to FAQs.
              </ListItem>
            </UnorderedList>
          </Flex>
        </>

        {/* Network Box End */}
      </Box>
      <BuyCryptoModal buyCryto={buyCryto} closeBuyCrypto={closeBuyCrypto} />

      <Card mt="1.4rem" p="1rem 0.6rem" bg="transparent">
        <DataTable columns={column} data={depositData} />
      </Card>
      <FAQModal checkFaq={checkFaq} closeFaq={closeFaq} />
    </>
  )
}
type byCryptoProps = {
  buyCryto: boolean
  closeBuyCrypto: () => void
  actionPreform?: () => void
}
function BuyCryptoModal({ buyCryto, closeBuyCrypto }: byCryptoProps) {
  return (
    <Modal size="md" isOpen={buyCryto} onClose={closeBuyCrypto}>
      <ModalOverlay />
      <ModalContent padding="10px">
        <ModalBody>
          <Flex direction="column">
            <Text className="fs--20 fw--400" mb="20px">
              Deposit and Withdrawal User Guide
            </Text>
            <Box
              className="fs--12 fw--400"
              bg="ChangeFilter"
              rounded="6px"
              padding="10px"
              mb="20px"
            >
              <Text color="muted">
                Before you deposit or withdraw, please read the user guide
                carefully to strengthen your risk awareness and avoid being
                scammed. Actively cooperate with the platform to fight against
                money laundering and terrorist financing.
              </Text>
            </Box>
            <Checkbox>
              <Flex className="fs--12 fw--400">
                <Text color="black">I have read and agree</Text>
                <Text color="primary.main">
                  《Deposit and Withdrawal User Guide》
                </Text>
              </Flex>
            </Checkbox>
            <Button className="fs--14 fw--400" mt="20px">
              Accept
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
type FaqProps = {
  checkFaq: boolean
  closeFaq: () => void
}
function FAQModal({ checkFaq, closeFaq }: FaqProps) {
  return (
    <Modal size="sm" isOpen={checkFaq} onClose={closeFaq}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enable Other Verification</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column">
            <Text className="fs--14 fw--400" color="muted">
              For your account security, please enable other verification
            </Text>
            <Flex
              alignItems="center"
              className="fs--14 fw--400"
              my="25px"
              color="primary.main"
            >
              <AiOutlineGoogle />
              <Text ml="10px">Bind Google Authenticator (Recommended)</Text>
            </Flex>
            <Divider />
            <Flex
              alignItems="center"
              className="fs--14 fw--400"
              my="25px"
              color="primary.main"
            >
              <AiOutlineGoogle />
              <Text ml="10px">Phone Number Verification</Text>
            </Flex>
            <Divider mb="10px" />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
const columnHelper = createColumnHelper<IDeposit>()
const column = [
  columnHelper.accessor('time', {
    cell: info => <Text className="fs--14 fw--400">{info.getValue()}</Text>,
    header: 'Time',
  }),
  columnHelper.accessor('type', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'Type',
  }),
  columnHelper.accessor('depositWallet', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'Deposit Wallet',
  }),
  columnHelper.accessor('asset', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'Asset',
  }),
  columnHelper.accessor('amount', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'Amount',
  }),
  columnHelper.accessor('destination', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'Destination',
  }),
  columnHelper.accessor('txID', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'TxID',
  }),
  columnHelper.accessor('status', {
    cell: info => (
      <Text className="fs--14 fw--400" color="Black">
        {info.getValue()}
      </Text>
    ),
    header: 'Status',
  }),
]
