// dummy calls
import { rest } from 'msw'

export const handlers = [
  rest.post('/auth/loginByEmail', (req, res, ctx) => {
    // Persist user's authentication in the session
    sessionStorage.setItem('is-authenticated', 'true')
    localStorage.setItem('is-authenticated', 'true')

    return res(
      // Respond with a 200 status code
      ctx.status(200),
      ctx.json({
        message: 'user authenticated',
        token: 'sdadasdasdasdasd',
      }),
    )
  }),

  rest.get('/user', (req, res, ctx) => {
    // Check if the user is authenticated in this session
    const isAuthenticated = sessionStorage.getItem('is-authenticated')

    if (!isAuthenticated) {
      // If not authenticated, respond with a 403 error
      return res(
        ctx.status(403),
        ctx.json({
          errorMessage: 'Not authorized',
        }),
      )
    }

    // If authenticated, return a mocked user details
    return res(
      ctx.status(200),
      ctx.json({
        username: 'admin',
      }),
    )
  }),

  rest.get('/project/getProjects?offset=0&limit=3', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json([
        {
          title: 'Torrington Car Park',
          description:
            'Mixed use development opportunity within the heart of Royal Tunbridge Wells<br>The Range Store & Torrington Car Park, Vale Road, Tunbridge Wells, Kent, TN1 1BT<br><br><strong>CAPITAL VALUE PSF</strong><br>£18.08<br><br><strong>PROPERTY TYPES</strong><br>Mixed Use, Retail, Mixed Use, Development and Land<br><br><strong>SITE AREA</strong><br>1.3 Acres (5,260.92 sq.m)<br><br><strong>INCOME</strong><br>£250,000 PA (£192,307.69 per sq.ft overall)',
          strategy:
            '<strong>INVESTMENT SUMMARY</strong><br><ul><li>Located directly opposite Royal Tunbridge Wells train station</li><li>Existing detached property occupying a 1.3 acre (0.53 hectare) site and comprising a building of 16,705 sqm (179,811 sqft) GIA including 705 sqm (29,127 sqft) NIA of retail accommodation and 421 car parking spaces</li><li>Site allocated for comprehensive redevelopment within the Emerging Local Plan</li><li>Positive pre-application response for a mixed use development comprising 105 residential units plus retail and car parking over 9 storeys</li><li>Held on a Headleashold interest of 125 years from 1986 (Tunbridge Wells Borough Council are the majority Freeholder)</li><li>Car Park subject to a 125 year underlease from 1986 back to the Council at peppercorn</li><li>Retail currently let to CDS (Superstores International) Ltd, (trading as The Range) until 29th April 2031 at a current passing rent of £250,000 per annum (£8.58 psf)</li><li>Upward only RPI review in April 2026 and breaks in April 2024 and three yearly afterwards</li><li>Extensive asset management opportunities including engaging with the council to comprehensively re-develop and extend headlease & underlease or enhance existing commercial income</li></ul>',
          imageThumbnail:
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%201@3x.png',
          image:
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%201@3x.png',
          media: [
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%201@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%202@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%203@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%204@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%205@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%206@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%207@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%208@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%209@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2010@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2011@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2012@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2013@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2014@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2015@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2016@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2017@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2018@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2019@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2020@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2021@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2021@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2022@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2023@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2024@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2025@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/torrington/Asset%2026@3x.png',
          ],
          userId: '6406dff2c225d3bbb957c499',
          ownerUserId: '6406dff2c225d3bbb957c499',
          fundedByUserId: '6406dff2c225d3bbb957c499',
          arv: 40.18,
          term: 12,
          termUnit: 'month',
          termEndDate: '2024-03-10',
          termEnd: 1710057756174,
          lat: 51.1299685,
          lng: 0.259431,
          location:
            'Torrington Car Park, Vale Road, Tunbridge Wells, Kent, TN1 1BT',
          APR: 14.73,
          areaLockedMeterSquare: 0,
          totalAreaMeterSquare: 0,
          fundRaised: 1305980.1099999999,
          fundRequired: 3250000,
          minimumInvestment: 100,
          isCommercial: true,
          isResidential: false,
          totalRewardEarned: 5932.237449999988,
          totalRewardClaimed: -440.12012500000014,
          totalRewardDispensed: 440.12012500000014,
          createdAt: '2023-03-10T08:02:36.177Z',
          updatedAt: '2023-04-05T06:30:39.366Z',
          id: '640ae41c86dcb04be6405a46',
        },
        {
          title: 'Octagon House',
          description:
            'Freehold office building offered with full Vacant Possession - Excellent Redevelopment Potential<br>Octagon House, Northwich, Cheshire, CW9 7RB',
          strategy:
            '<strong>INVESTMENT SUMMARY</strong><br><ul><li>Located in Gadbrook Park, Northwich, in the heart of Cheshire</li><li>Northwich is situated approximately 24 miles south west of Manchester, 27 miles south east of Liverpool and 18 miles east of Chester</li><li>Excellent communications being located on the A556 dual carriageway providing direct access to J19 of the M6 (6 miles) and J9 of the M56 (9 miles)</li><li>Detached self-contained office building extending to 51,169 sf ft (4,753.7 sq m) NIA, arranged over ground and first floors</li><li>Site area of 2.88 hectares (7.13 acres) equating to a low site coverage of approximately 13%</li><li>318 secure car parking spaces providing an excellent ratio 1:161 sq ft</li><li>Offered with full Vacant Possession</li><li>Exciting opportunity to refurbish the existing or for a potential redevelopment f the site to offer alternative uses including residential (subject to obtaining the necessary consents)</li><li>Freehold</li></ul>',
          imageThumbnail:
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%203@3x.png',
          image:
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%203@3x.png',
          media: [
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%203@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%204@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%205@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%206@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%207@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%208@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%209@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%2010@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%2011@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%2012@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%2013@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%2014@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%2015@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%2016@3x.png',
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/octagonehouse/Asset%2017@3x.png',
          ],
          userId: '6406dff2c225d3bbb957c499',
          ownerUserId: '6406dff2c225d3bbb957c499',
          fundedByUserId: '6406dff2c225d3bbb957c499',
          arv: 6.6,
          term: 12,
          termUnit: 'month',
          termEndDate: '2024-03-10',
          termEnd: 1710056033332,
          lat: 53.247936,
          lng: -2.4885357,
          location: 'Octagon House, Northwich, Cheshire, CW9 7RB',
          APR: 14.73,
          areaLockedMeterSquare: 0,
          totalAreaMeterSquare: 0,
          fundRaised: 247424,
          fundRequired: 3750000,
          minimumInvestment: 100,
          isCommercial: true,
          isResidential: false,
          totalRewardEarned: 2935.504875,
          totalRewardClaimed: 0,
          totalRewardDispensed: 0,
          createdAt: '2023-03-10T07:33:53.335Z',
          updatedAt: '2023-04-05T07:55:38.714Z',
          id: '640add6186dcb04be64059ad',
        },
        {
          title: 'Absolute House',
          description:
            'Newly converted block of 91 apartments with further development potential<br>Absolute House, Lyon Way, Frimley, GU16 7EX',
          strategy:
            '<strong>INVESTMENT SUMMARY</strong><br>Freehold unbroken block<ul><li>91 apartments with further development potential</li><li>Full Vacant Possession</li><li>Estimated Rental Value of the 91 apartments (when fully let): £1,068,000 per annum</li><li>Potential to increase the ERV subject to further development works</li><li>Well located close to Frimley town centre and with good transport connections to London (via train) and motorways via M3/M25</li></ul>Offers in excess of £17,500,000 are invited subject to contract for the Freehold interest, reflecting a capital value of £409 psf (net saleable area of the flats)',
          imageThumbnail:
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/absolute%20house/72c891b9a9bd3ccaf31f8a87dce1ab4b.jpeg',
          image:
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/absolute%20house/72c891b9a9bd3ccaf31f8a87dce1ab4b.jpeg',
          media: [
            'https://api.buildingsup.co.uk/media-upload/mediaFiles/absolute%20house/72c891b9a9bd3ccaf31f8a87dce1ab4b.jpeg',
          ],
          userId: '6406dff2c225d3bbb957c499',
          ownerUserId: '6406dff2c225d3bbb957c499',
          fundedByUserId: '6406dff2c225d3bbb957c499',
          arv: 0.58,
          term: 12,
          termUnit: 'month',
          termEndDate: '2024-03-10',
          termEnd: 1710053835097,
          lat: 51.31622486855347,
          lng: -0.7487602239988412,
          location: 'Absolute House, Lyon Way, Frimley, GU16 7EX',
          APR: 13.23,
          areaLockedMeterSquare: 0,
          totalAreaMeterSquare: 0,
          fundRaised: 101844,
          fundRequired: 17500000,
          minimumInvestment: 100,
          isCommercial: false,
          isResidential: true,
          totalRewardEarned: 1144.229625,
          totalRewardClaimed: 0,
          totalRewardDispensed: 0,
          createdAt: '2023-03-10T06:57:15.106Z',
          updatedAt: '2023-04-04T05:56:14.120Z',
          id: '640ad4cba0706e6181521eb4',
        },
      ]),
    )
  }),
]
