import React from 'react'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useMediaQuery,
} from '@chakra-ui/react'
import {
  DepositIcon,
  WithdrawIcon,
  Investments,
  Funds,
  AccAndSecurity,
  RecordAssetsIcon,
} from 'assets/icons'
import AssetTransfer from './withdraw'
import Deposit from './deposit'
import MyFunds from './myFunds'
import AccountAndSecurity from './accountAndSecurity'
import P2PHistory from './p2pHistory'
import Header from 'components/header'
export default function Profile() {
  const [isLargerThan480] = useMediaQuery('(min-width: 480px)')

  return (
    <>
      <Header />
      {isLargerThan480 ? (
        <Tabs orientation="vertical">
          <TabList
            borderLeft="0"
            borderRight="2px"
            borderRightColor="#EBEEEF"
            p={{ base: '0', lg: '40px 24px' }}
          >
            <TabItem icon={<Investments />} label="My Investments" />
            <TabItem icon={<Funds />} label="My Funds" />
            <TabItem icon={<DepositIcon />} label="Deposit" />
            <TabItem icon={<WithdrawIcon />} label="Withdraw" />
            <TabItem icon={<RecordAssetsIcon />} label="P2P History" />
            <TabItem icon={<AccAndSecurity />} label="Account & Security" />
          </TabList>
          <TabPanels>
            <TabPanel className="fs--16 fw--400"></TabPanel>
            <TabPanel>
              <MyFunds />
            </TabPanel>
            <TabPanel>
              <Deposit />
            </TabPanel>
            <TabPanel>
              <AssetTransfer />
            </TabPanel>
            <TabPanel>
              <P2PHistory />
            </TabPanel>
            <TabPanel>
              <AccountAndSecurity />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <Tabs>
          <TabList flexFlow="wrap">
            <TabItemMobileView icon={<Investments />} label="My Investments" />
            <TabItemMobileView icon={<Funds />} label="My Funds" />
            <TabItemMobileView icon={<DepositIcon />} label="Deposit" />
            <TabItemMobileView icon={<WithdrawIcon />} label="Withdraw" />
            <TabItemMobileView
              icon={<RecordAssetsIcon />}
              label="P2P History"
            />
            <TabItemMobileView
              icon={<AccAndSecurity />}
              label="Account & Security"
            />
          </TabList>
          <TabPanels>
            <TabPanel className="fs--16 fw--400"></TabPanel>
            <TabPanel>
              <MyFunds />
            </TabPanel>
            <TabPanel>
              <Deposit />
            </TabPanel>
            <TabPanel>
              <AssetTransfer />
            </TabPanel>
            <TabPanel>
              <P2PHistory />
            </TabPanel>
            <TabPanel>
              <AccountAndSecurity />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </>
  )
}
interface Props {
  icon: JSX.Element
  label: string
}
function TabItem({ icon, label }: Props) {
  return (
    <Tab
      _selected={{
        bg: 'primary.50',
        borderRight: '3px solid #924ece',
        color: 'primary.main',
      }}
      px="0"
      mx="0"
    >
      <Button
        leftIcon={icon}
        variant="ghost"
        className="fs--16 fw--400"
        w="230px"
        color="inherit"
        _hover={{}}
        _active={{}}
        justifyContent="left"
      >
        {label}
      </Button>
    </Tab>
  )
}
function TabItemMobileView({ icon, label }: Props) {
  return (
    <Tab
      px="0"
      mx="0"
      _selected={{
        bg: 'primary.50',

        color: 'primary.main',
      }}
    >
      <Button
        leftIcon={icon}
        variant="ghost"
        className="fs--16 fw--400"
        color="inherit"
        _hover={{}}
        _active={{}}
      >
        {label}
      </Button>
    </Tab>
  )
}
