import { IP2pSellerAcc, P2PBuyerData } from 'utils/types/p2p.type'
import C1 from 'assets/images/Currency1.png'

export const p2pSellData: P2PBuyerData[] = [
  {
    advitizer: 'Cybil Trader',
    price: '233.09 PKR',
    limitAvilability: '225.00 USDT',
    chain: 'Polygon',
    action: 'Sell',
  },
  {
    advitizer: 'Cybil Trader',
    price: '233.09 PKR',
    limitAvilability: '225.00 USDT',
    chain: 'Polygon',
    action: 'Sell',
  },
  {
    advitizer: 'Cybil Trader',
    price: '233.09 PKR',
    limitAvilability: '225.00 USDT',
    chain: 'Polygon',
    action: 'Sell',
  },
  {
    advitizer: 'Cybil Trader',
    price: '233.09 PKR',
    limitAvilability: '225.00 USDT',
    chain: 'Polygon',
    action: 'Sell',
  },
  {
    advitizer: 'Cybil Trader',
    price: '233.09 PKR',
    limitAvilability: '225.00 USDT',
    chain: 'Polygon',
    action: 'Sell',
  },
  {
    advitizer: 'Cybil Trader',
    price: '233.09 PKR',
    limitAvilability: '225.00 USDT',
    chain: 'Polygon',
    action: 'Sell',
  },
  {
    advitizer: 'Cybil Trader',
    price: '233.09 PKR',
    limitAvilability: '225.00 USDT',
    chain: 'Polygon',
    action: 'Sell',
  },
  {
    advitizer: 'Cybil Trader',
    price: '233.09 PKR',
    limitAvilability: '225.00 USDT',
    chain: 'Polygon',
    action: 'Sell',
  },
]
export const p2pSellerAcc: IP2pSellerAcc[] = [
  {
    image: C1,
    name: 'USDT',
    available: 3232,
    amount: 322,
    BTCvalue: 34.4,
  },
  {
    image: C1,
    name: 'USDT',
    available: 3232,
    amount: 322,
    BTCvalue: 34.4,
  },
  {
    image: C1,
    name: 'USDT',
    available: 3232,
    amount: 322,
    BTCvalue: 34.4,
  },
]
