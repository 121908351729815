import React from 'react'
// import P2PLayout from 'layout/P2P'
import DataTable from 'components/table'
import { SellerOrderData } from 'utils/data/sellerAccountOrders'
import { SellerOrderCloumnsData } from 'utils/data/sellerAccountOrders'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useMediaQuery,
  Card,
  HStack,
  Text,
} from '@chakra-ui/react'
export default function MyOrderListing() {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  return (
    <Tabs>
      <TabList border="0px" gap="20px" mb="1rem">
        <Tab px="0">In Progress </Tab>
        <Tab px="0">Completed</Tab>
        <Tab px="0">Canceled</Tab>
        <Tab px="0">All</Tab>
      </TabList>

      <TabPanels>
        <TabPanel p="0">
          {isLargerThan768 ? (
            <DataTable
              columns={SellerOrderCloumnsData}
              data={SellerOrderData}
            />
          ) : (
            <>
              {[1, 2, 3, 4, 5].map(index => (
                <Card
                  key={index}
                  mt="12px"
                  p="12px"
                  className="fs--14"
                  bg="#FBFBFB"
                >
                  <HStack justify="space-between">
                    <Text>Canceled</Text>
                    <Text>2022-11-11 10:47:46</Text>
                  </HStack>
                  <HStack justify="space-between" my="12px">
                    <HStack>
                      <Text color="red.500">Sell</Text>
                      <Text>652asXe0...283330nM</Text>
                    </HStack>
                    <HStack>
                      <Text>Amount</Text>
                      <Text>400 USDT</Text>
                    </HStack>
                  </HStack>
                  <HStack justify="space-between">
                    <HStack>
                      <Text>Qty</Text>
                      <Text>133.6 USDT</Text>
                    </HStack>
                    <HStack>
                      <Text>Price</Text>
                      <Text>264PKR/USDT</Text>
                    </HStack>
                  </HStack>
                </Card>
              ))}
            </>
          )}
        </TabPanel>
        <TabPanel p="0"></TabPanel>
        <TabPanel p="0"></TabPanel>
        <TabPanel p="0"></TabPanel>
      </TabPanels>
    </Tabs>
    // </P2PLayout>
  )
}
