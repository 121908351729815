import React, { useState } from 'react'
import {
  Text,
  Stack,
  Flex,
  Button,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Radio,
  Input,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Select,
} from '@chakra-ui/react'
import PakCurrency from 'assets/images/PakistanCurrency.png'
import C1 from 'assets/images/Currency1.png'
import { ChevronDownIcon } from '@chakra-ui/icons'
export default function CreateOrder() {
  const [orderStep, setorderStep] = useState(1)
  return (
    <Stack w="100%">
      <Flex justifyContent="center">
        {orderStep === 1 ? <StepOne /> : <StepTwo />}
      </Flex>
      <Flex justifyContent="end">
        <Box width={{ base: '100%', md: '75%' }} textAlign="end" mt="15px">
          {orderStep === 1 ? (
            <Button
              className="fs--14 fw--400"
              w={{ base: '100%', md: '195px' }}
              ml="auto"
              onClick={() => {
                setorderStep(2)
              }}
            >
              Next
            </Button>
          ) : (
            <Flex alignItems="end" justifyContent="end">
              <Button
                className="fs--14 fw--400"
                mr="15px"
                w="195px"
                bg="#E6E6E6"
                color="muted"
                onClick={() => {
                  setorderStep(1)
                }}
              >
                Previous
              </Button>
              <Button className="fs--14 fw--400" w="195px">
                Post Ad
              </Button>
            </Flex>
          )}
        </Box>
      </Flex>
    </Stack>
  )
}
function StepOne() {
  const [orderType, setorderType] = useState('buy')
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="start"
      width={{ base: '100%', md: '75%' }}
      padding="40px"
      bg="#FBFBFB"
    >
      <Box w="100%" mb="20px">
        <Button
          onClick={() => {
            setorderType('buy')
          }}
          _hover={{}}
          _active={{}}
          className="fs--16 fw--500"
          w="50%"
          bg={orderType === 'buy' ? 'white' : 'ChangeFilter'}
          color={orderType === 'buy' ? 'orderText' : 'muted'}
        >
          I want to buy
        </Button>
        <Button
          onClick={() => {
            setorderType('sell')
          }}
          _hover={{}}
          _active={{}}
          className="fs--16 fw--500"
          w="50%"
          bg={orderType === 'sell' ? 'white' : 'ChangeFilter'}
          color={orderType === 'sell' ? 'orderText' : 'muted'}
        >
          I want to sell
        </Button>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Flex direction="column" w="48%">
          <Text
            className="fs--16 fw--400"
            mb="10px"
            color="muted"
            textAlign="start"
          >
            Asset
          </Text>
          <Menu>
            <MenuButton
              bg="createOrderOption"
              textAlign="start"
              leftIcon={
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src={C1}
                  alt="Fluffybuns the destroyer"
                  mr="12px"
                  color="black"
                />
              }
              as={Button}
              rightIcon={<ChevronDownIcon color="muted" />}
            >
              <Text className="fs--14 fw--400" color="muted">
                USDT
              </Text>
            </MenuButton>
            <MenuList>
              <MenuItem minH="48px">
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src={C1}
                  alt="Fluffybuns the destroyer"
                  mr="12px"
                />
                <span>Fluffybuns the Destroyer</span>
              </MenuItem>
              <MenuItem minH="40px">
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src="https://placekitten.com/120/120"
                  alt="Simon the pensive"
                  mr="12px"
                />
                <span>Simon the pensive</span>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <Flex direction="column" w="48%">
          <Text mb="10px" color="muted" textAlign="start">
            Fiat
          </Text>
          <Menu>
            <MenuButton
              bg="createOrderOption"
              textAlign="start"
              leftIcon={
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src={PakCurrency}
                  alt="Fluffybuns the destroyer"
                  mr="12px"
                />
              }
              as={Button}
              rightIcon={<ChevronDownIcon color="muted" />}
            >
              <Text className="fs--14 fw--400" color="muted">
                PKR
              </Text>
            </MenuButton>
            <MenuList>
              <MenuItem minH="48px">
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src={C1}
                  alt="Fluffybuns the destroyer"
                  mr="12px"
                />
                <span>Fluffybuns the Destroyer</span>
              </MenuItem>
              <MenuItem minH="40px">
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src="https://placekitten.com/120/120"
                  alt="Simon the pensive"
                  mr="12px"
                />
                <span>Simon the pensive</span>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
      <Box
        mb="30px"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="start"
      >
        <Text textAlign="start" my="10px" color="muted">
          Network
        </Text>
        <Select bg="createOrderOption" border="0px">
          <option color="muted" value="option1">
            ERC-20
          </option>
          <option color="muted" value="option2">
            Option 2
          </option>
          <option color="muted" value="option3">
            Option 3
          </option>
        </Select>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Flex alignItems="start" direction="column" w="48%">
          <Text className="fs-16 fw--400" mb="15px" color="muted">
            Your Price
          </Text>
          <Text className="fs--20 fw--400" color="black.200">
            Rs 323.42
          </Text>
        </Flex>
        <Flex alignItems="start" direction="column" w="48%">
          <Text className="fs-16 fw--400" mb="15px" color="muted">
            Highest Order Price
          </Text>
          <Text className="fs--20 fw--400" color="black.200">
            Rs 325.90
          </Text>
        </Flex>
      </Box>
      <Box
        mt="15px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Flex alignItems="start" direction="column" w="48%">
          <Text mb="15px" className="fs--16 fw--400" color="muted">
            Price Type
          </Text>
          <Radio>
            <Text className="fs--16 fw--400" mr="15px">
              Floating
            </Text>
          </Radio>
        </Flex>
        <Flex alignItems="start" direction="column" w="48%">
          <Radio mt="40px">
            <Text className="fs--16 fw--400" mr="15px">
              Fixed
            </Text>
          </Radio>
        </Flex>
      </Box>
    </Box>
  )
}
function StepTwo() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box
      border="0px"
      display="flex"
      flexDirection="column"
      alignItems="start"
      width={{ base: '100%', md: '75%' }}
      padding="40px"
      bg="#FBFBFB"
    >
      <Box w="100%" mb="15px">
        <Flex direction="column">
          <Text
            className="fs--16 fw--400"
            mb="15px"
            textAlign="start"
            color="muted"
          >
            Totall Ammount
          </Text>
          <Input placeholder="91" />
        </Flex>
      </Box>
      <Box
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex direction="column" width="48%">
          <Text
            className="fs--16 fw--400"
            mb="15px"
            textAlign="start"
            color="muted"
          >
            Min Ammount per Order
          </Text>
          <Input placeholder="100" />
        </Flex>
        <Flex direction="column" width="48%">
          <Text
            className="fs--16 fw--400"
            mb="15px"
            textAlign="start"
            color="muted"
          >
            Max Ammount per Order
          </Text>
          <Input placeholder="5000" />
        </Flex>
      </Box>
      <Flex mt="15px" alignItems="center" justifyContent="center" w="100%">
        <Divider width="75%" height="10px"></Divider>
      </Flex>
      <Box width="100%" border="0px" mt="15px">
        <Flex alignItems="center" justifyContent="space-between" mb="15px">
          <Text color="muted">Payment Method</Text>
          <Button onClick={onOpen} bg="transparent" color="orderText">
            + Add
          </Button>
          <Modal size="lg" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent padding="35px">
              <ModalBody p={0}>
                <Text mb="21px" className="fs--16 fw--600">
                  Add Bank Details
                </Text>
                <Text color="muted" mb="15px">
                  Card information
                </Text>
                <Input
                  className="fs--14 fw--400"
                  border="1px solid gray"
                  width="100%"
                  placeholder="Card Number"
                ></Input>
                <Flex
                  mt="15"
                  mb="30px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Input
                    className="fs--14 fw--400"
                    width="48%"
                    placeholder="MM/YY"
                  ></Input>
                  <Input
                    className="fs--14 fw--400"
                    width="48%"
                    placeholder="CVC"
                  ></Input>
                </Flex>
                <Text className="fs--16 fw--400" color="muted" mb="15px">
                  Country or region
                </Text>
                <Flex
                  mb="20px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Input
                    className="fs--14 fw--400"
                    width="48%"
                    placeholder="United States"
                  ></Input>
                  <Input
                    className="fs--14 fw--400"
                    width="48%"
                    placeholder="Zip Code"
                  ></Input>
                </Flex>
              </ModalBody>
              <Flex justifyContent="end">
                <Button
                  className="fs--14 fw--400"
                  mr="15px"
                  w="127px"
                  bg="#E6E6E6"
                  color="muted"
                  onClick={onClose}
                >
                  Discard
                </Button>
                <Button className="fs--14 fw--400" w="127px">
                  Post Ad
                </Button>
              </Flex>
            </ModalContent>
          </Modal>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          border="0px solid red"
          mb="15px"
        >
          <Radio>
            {' '}
            <Text className="fs--16 fw--600" color="muted">
              Bank Transfer
            </Text>
          </Radio>
          <Text className="fs--16 fw--500" bg="transparent" color="muted">
            M PAY
          </Text>
        </Flex>
        <Flex direction="column">
          <Text
            className="fs--16 fw--400"
            mb="15px"
            textAlign="start"
            color="muted"
          >
            Remarks
          </Text>
          <Input placeholder="" />
        </Flex>
      </Box>
    </Box>
  )
}
